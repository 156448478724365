import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ColouredHeaderText from "../../../common/ColouredHeaderText";
import { useTranslation } from "react-i18next";

const Accordion = withStyles({
  root: {
    backgroundColor: "#0E1648",
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: "4px",
    padding: "0 16px",
    "&:before": {
      display: "none",
    },
    "&:last-of-type": {
      marginBottom: "0px",
    },
    "&$expanded": {
      margin: 0,
      marginBottom: "4px",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#0E1648",
    padding: "0px",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "20px 0",
    "&$expanded": {
      margin: "20px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "16px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    color: "#FFFFFF",
  },
}))(MuiAccordionDetails);

// const faqArray = ['WIS', 'WIPE', 'HSC', 'ISG', 'WSN', 'WTCS']
const faqArray = [
  {
    name: "WIS",
    link: "https://media.publit.io/file/SPORTREX-WHITE-PAPER-2022-version1.pdf",
    text: "click here to view white paper",
    textA: "",
  },
  {
    name: "WIPE",
    link: "",
    text: "",
    textA: "",
  },
  {
    name: "HSC",
    link: "https://media.publit.io/file/SPORTREX-WHITE-PAPER-2022-version1.pdf",
    text: "click here to view white paper",
    textA: "Whitepaper",
  },
  {
    name: "ISG",
    link: "",
    text: "",
    textA: "",
  },
  {
    name: "WSN",
    link: "",
    text: "",
    textA: "",
  },
  {
    name: "WTCS",
    link: "",
    text: "",
    textA: "",
  },
];
const MobileFaq = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section className="sm:hidden px-4 my-20">
      <div className="mb-4 flex justify-center">
        <ColouredHeaderText text={"FAQ"} />
      </div>
      <div>
        {faqArray.map((item, i) => (
          <Accordion
            key={item + i}
            square
            expanded={expanded === `faq-${i}`}
            onChange={handleChange(`faq-${i}`)}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon className="text-grey" />}
              aria-controls={`faq-${i}-content`}
              id={`faq-${i}`}
            >
              <ColouredHeaderText
                text={t(item.name)}
                fontSize="text-base sm:text-[22px]"
              />
            </AccordionSummary>
            <AccordionDetails>
              <p className="biome-regular">
                {t(item.name + 1)}
                <span className="ml-1">
                  {item.textA && (
                    <a
                      href="https://media.publit.io/file/SPORTREX-WHITE-PAPER.pdf"
                      className="underline text-yellow"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Whitepaper")}.
                    </a>
                  )}
                </span>
              </p>
              {t(item.name + 2) !== item.name + 2 && (
                <p className="biome-regular">
                  {t(item.name + 2)}
                  <span className="ml-1">
                    {item.link && (
                      <a
                        href="https://media.publit.io/file/SPORTREX-WHITE-PAPER.pdf"
                        className="underline text-yellow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Whitepaper")}.
                      </a>
                    )}
                  </span>
                </p>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default MobileFaq;
