import React from 'react'
// import { useTranslation } from "react-i18next";
import { firstData, secondData, thirdData } from "./sptData";


// The sportrex native token, SPT, is minted on multi-chain networks. These includes:
const styles = {
  container: "w-full flex flex-col-reverse px-4 py-4  ",
  textContainer: "w-full  flex flex-col space-y-4 pb-4 mt-4",

  parker: "w-full  flex flex-col",
  singleItem: "w-full flex flex-wrap ",
  name: "text-white text-[12px] biome-regular mr-2 ",
  value: "text-yellow  text-[12px] biome-regular",
  imageContainer: "w-full h-full",
  img: "w-10/12 h-auto mx-auto",
};
const SPT = () => {
  // const { t } = useTranslation();
  
  
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.parker}>
          {firstData.map((item, index) => {
            return (
              <div className={styles.singleItem} key={index}>
                <p className={styles.name}>{item.name}</p>
                <p className={styles.value}>{item.value}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.parker}>
          {secondData.map((item, index) => {
            return (
              <div className={styles.singleItem} key={index}>
                <p className={styles.name}>{item.name}</p>
                <p className={styles.value}>{item.value}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.parker}>
          {thirdData.map((item, index) => {
            return (
              <div className={styles.singleItem} key={index}>
                <p className={styles.name}>{item.name}</p>
                <p className={styles.value}>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img
          src="https://media.publit.io/file/mutichain.png"
          alt="piechart"
          className={styles.img}
        />
      </div>
    </div>
  );
}

export default SPT