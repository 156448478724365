import React from "react";
import styles from "./index.module.css";

const CustomButton2 = ({ onClick, small = false, variant = false, text }) => {
  return (
    <div
      className={`
                ${styles.container} 
                ${
                  variant
                    ? "border-yellow hover:bg-yellow"
                    : "border-lightBlue hover:bg-lightBlue"
                }

            `}
    >
      <button
        className={`
                    ${styles.button} 
                    ${
                      variant
                        ? "bg-yellow border-yellow text-semiBlue"
                        : "bg-lightBlue border-lightBlue text-white"
                    }
                    ${
                      small
                        ? "min-w-[136px] sm:min-w-[150px] h-[36px]"
                        : "min-w-[136px] sm:min-w-[203px] h-[46px]"
                    } 
                    biome-semibold`}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default CustomButton2;
