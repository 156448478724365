import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import sendicon from "../../assets/sendicon.png";
import image from "../../assets/subBG.png";
import axios from "axios";
import SuccessSnack from "../Snackbar/SuccessSnack"
import ErrorSnack from '../Snackbar/ErrorSnack'
import CircularProgress from "@material-ui/core/CircularProgress";

const url = "https://sportrex-be.herokuapp.com/api/subscribe";

const styles = {
  container: "md:hidden w-full h-full bg-lightBlue flex justify-center md:px-24",
  contentContainer: "mt-2 flex flex-col justify-center  items-center px-2",
  inputContainer: "w-11/12 sm:w-full lg:w-8/12 bg-white flex mt-[1rem] justify-between rounded-[2.5rem] px-6 py-4",
  input: "border-none outline-none w-10/12 text-grey-dark md:text-lg biome-regular",
  title: "text-[1.375rem] sm:text-[1.5rem] text-white mb-12 lg:mb-2 lg:text-[2.5rem] mt-[5vh] lg:mt-[7.5rem] text-center biome-bold",
  img: "w-8 h-8 cursor-pointer",
  validation: "text-yellow text-xl biome-regular",
};

const Subscribe = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
       setTimeout(() => {
         setSuccess(true);
         setMessage(
           "Congratulations you have successfully subscribed to our news letter"
         );
         setEmail("");
         setLoading(false);
       }, 3000);
    // try {
    //   const response = await axios.post(url, { email })
    //   if (response.status) {
    //     setSuccess(true);
    //     setMessage(response.data?.message);
    //     setEmail("");
    //   } else {
    //     setError(true);
    //     setMessage(response.data?.message);
    //   }
    // }
    // catch (error) {
    //   setError(true);
    //   setMessage(error.response.data?.error);
    // }
    // setLoading(false)
  }
  return (
    <>
      <section
        className={styles.container}
        style={{
          background: "transparent ",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "60vh",
          width: "100%",
          borderRadius: "50px",
        }}
      >
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{t("be_part")}</h1>
          <form className={styles.inputContainer} onSubmit={handleSubmit}>
            <input
              className={styles.input}
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="johndoe@gmail.com"
              name='email'
              required
            />
            {loading ? (
              <CircularProgress color="inherit" className="text-blue" />
            ) : (
              <button type='submit'>
                <img
                  className={styles.img}
                  src={sendicon}
                  alt="subscribe"
                  role={'button'}
                />
              </button>
            )}
          </form>
        </div>
      </section>
      <SuccessSnack message={message} open={success} setOpen={setSuccess} />
      <ErrorSnack open={error} setOpen={setError} message={message} />
    </>
  );
}

export default Subscribe
