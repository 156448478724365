import React from "react";
import { useTranslation } from "react-i18next";

const InfluencerButton = ({ text, action }) => {
  const { t } = useTranslation();

  return (
    <div className="btn-influencer">
      <div className="holder-influencer"> </div>
      <button
        className="influencer biome-semibold font-semibold"
        onClick={action}
      >
        {t(`${text}`)}
      </button>
    </div>
  );
};

export default InfluencerButton;
