import React, { useState,useRef } from "react";
import Success from "../Feedback/Success";
import Error from "../Feedback/Error";
import { FaTimes } from "react-icons/fa";
import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from "./styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomButton from "../Button/CustomButton";
import { socials } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";



const url =
  "https://sportrex-webiste-api-fb47fa67972d.herokuapp.com/api/v1/sportrex/waitlist";

const Airdrop = ({ handleClose }) => {
  const history = useHistory();
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const initialState = {
    email: '',
    spt_address: '',
    telegram_link: '',
    discord_link: '',
    twitter_link: ''
  }
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState(initialState)
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    // setTimeout(() => {
    //   setSuccess(true);
    //   setMessage("Congratulations you have successfully joined the waitlist");
    //   setValues(initialState);
    //   setLoading(false)
    // }, 3000);
    try {
         const recaptcha_token = captchaRef.current.getValue();
         captchaRef.current.reset();
      const { email, spt_address, twitter_link, discord_link, telegram_link } = values
      const response = await axios.post(url, {
        email:email,
        address:spt_address,
        
      });
      
      if (response?.data?.success ) {
        setSuccess(true)
        setMessage(response.data?.message)
        setValues(initialState)
      } else {
        setError(true)
        setMessage(response.data?.error)
      }
    } catch (error) {
      setError(true);
      setMessage(error?.response?.data?.message);
    }
    setLoading(false)
  };

  const handleChange = (e) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleBack = () => {
    setError(false);
    setSuccess(false)
    history.push("/home")

  }

  // const handleVerify = async (token) => {
  //   try {
  //     const res = await axios.post(`${CAPTCHA_VERIFY_URL}?secret=${process.env.REACT_APP_SECRET_KEY}&response=${token}`)
  //     console.log(res);
  //   } catch (error) {
  //     alert(error.response?.data?.message)
  //   }
  // }

  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.headerSession}>
          <div className={styles.header}>
            <h1 className={styles.headerTitle}>{t("Get_Airdrop")}</h1>
            <FaTimes className={styles.closeIcon} onClick={handleClose} />
          </div>
        </div>
        {success && <Success message={message} handleBack={handleBack} />}
        {error && <Error message={message} handleBack={handleBack} />}
        {!error && !success && (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <div className={styles.socials}>
              {socials?.map((social) => (
                <div key={social.key} className="mb-10">
                  <h4 className={styles.socialTitle}>{social.title}</h4>
                  <div className="flex items-center gap-4">
                    <img
                      src={`/assets/icons/${social.key}.png`}
                      width={32}
                      height={32}
                      alt={social.key + " icon"}
                    />
                    <a
                      className="text-white font-[600]"
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {social.link}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.singleField}>
              <label className={styles.label}>{t("spt_address")}</label>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  className={styles.input}
                  placeholder="0x061b4a25BE391Aa2034fC7aC5b631B1066423259"
                  value={values.spt_address}
                  onChange={handleChange}
                  name="spt_address"
                  required
                />
              </div>
            </div>
            <div className={styles.singleField}>
              <label className={styles.label}>{t("reg_email")}</label>
              <div className={styles.inputContainer}>
                <input
                  className={styles.input}
                  placeholder="gamersmerge@sportrex.eth"
                  type="email"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-2">

            <ReCAPTCHA
              sitekey="6LejdFMiAAAAADgW68Bowi0slHXTSSmKy9puRGi9"
              ref={captchaRef}
            />
            </div>
            {/* <Reaptcha sitekey={process.env.REACT_APP_SITE_KEY} onVerify={handleVerify} /> */}
            <div className={styles.btnContainer}>
              {loading ? (
                <div className={styles.wait}>
                  <p className={styles.waiting}>{t("p_wait")}</p>
                  <CircularProgress color="inherit" className="text-white" />
                </div>
              ) : (
                <div role={"button"} type="submit">
                  <CustomButton text={t("submit")} onClick={() => {}} />
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Airdrop;
