import React from "react";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";
import ColouredHeaderText from "../common/ColouredHeaderText";

// {t("")}
const styles = {
  parentContainer: "w-full h-full px-4 sm:px-16 relative max-w-[1440px] mx-auto",
  container: "w-full h-full flex flex-col md:flex-row items-center align-center justify-between gap-6 md:gap-[68px]",
  sectionA: "w-full h-full lg:flex-grow-1 lg:w-6/12 order-2 md:order-1",
  sectionContainer: "w-full flex flex-col justify-center",
  aboutTitle: "hidden lg:block mb-4",
  aboutTitleB: "block lg:hidden mb-6",
  storyA: "text-white text-base md:text-md xxl:text-lg biome-regular m-story leading-[25px] md:leading-9 mb-2",
  story: "text-white text-base md:text-md xxl:text-lg biome-regular m-story leading-[25px] md:leading-9 mb-2",
  sectionB: "max-w-[600px] md:min-h-max order-1 md:order-2 md:rounded-[30px] overflow-hidden"
};

const About = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.parentContainer} id="About">
      <div className="blur-circle bg-index" />
      <div className={styles.container} style={{ zIndex: "1000" }}>
        <Fade left>
          <div className={styles.sectionA}>
            <div className={styles.sectionContainer}>
              <Fade delay={100}>
                <div className={styles.aboutTitle}>
                  <ColouredHeaderText text={t("about_sportrex")} />
                </div>
              </Fade>
              <Fade bottom delay={200}>
                <p className={styles.story}>{t("about_story")}</p>
              </Fade>
              <Fade bottom delay={300}>
                <p className={styles.storyA}>{t("about_story2")}</p>
              </Fade>
            </div>
          </div>
        </Fade>
        <div className={styles.sectionB}>
          <div className={styles.aboutTitleB}>
            <ColouredHeaderText text={t("about_sportrex")} />
          </div>
          <ReactPlayer
            url="https://media.publit.io/file/h_480/sportex.mp4"
            controls={true}
            playing={false}
            height={"100%"}
            width={"100%"}
            style={{
              borderRadius: '30px'
            }}
          />
        </div>
      </div>
      <div className="blur-circle-r bg-index" />
    </section>
  );
};

export default About;
