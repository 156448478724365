import React, { Suspense,useState,useEffect } from "react";

import {
  Footer,
  Navbar,
  // About,
  MobileNavbar,
  DesktopHero,
  Partners,
  Tokennomics,
  ListComponent,
  Features,
  SportrexVERSE,
  Subscribe,
  SportrexApi,
  SportrexCard,
  GamersWallet,
  
  // SportrexEcosystem,
  MobileFeatures, Roadmap, Loading, MobileRoadmap,
   Ecosystem2,
} from "../components";
import MobileTokennomics from '../components/MobileScreen/Tokenomics'
import MobileSubscribe from '../components/MobileScreen/MobileSubscribe'
import DesktopFaq from "../components/FAQ/Desktop";
import MobileFaq from "../components/FAQ/Mobile";
import AddsCard from "../components/AddsCard";

const OtherComponent = React.lazy(() => import("../components/About"));
//const AddsCard = React.lazy(() => import("../components/AddsCard"));

const styles = {
  parentContainer: "overflow-x-hidden w-full h-full min-h-screen flex justify-center",
  container: "w-full h-full flex flex-col items-center relative",
  mobileNavbar: "lg:hidden w-full",
  desktopNavbar: "hidden lg:flex w-full",
};

const HomePage = () => {
    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
      if (window.innerWidth > 1023) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    }, [isDesktop]);
  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.container}>
          {isDesktop ? (
            <div className={styles.desktopNavbar}>
              <Navbar />
            </div>
          ) : (
            <div className={styles.mobileNavbar}>
              <MobileNavbar />
            </div>
          )}

          <DesktopHero />
          {/* <Partners /> */}
          <div className="my-[4rem] lg:my-[6rem]">

          </div>
          <Suspense fallback={<Loading />}>
            <OtherComponent />
          </Suspense>
          <SportrexVERSE />
          {/* <YellowPartner /> */}

          <Suspense fallback={<Loading />}>
            <AddsCard />
          </Suspense>
          <Features />
          <MobileFeatures />
          <GamersWallet />
          <SportrexCard />
          <Ecosystem2 />
          <Tokennomics />
          <MobileTokennomics />
          <Roadmap />
          <MobileRoadmap />
          <SportrexApi />
          <ListComponent />
          <DesktopFaq />

          <MobileFaq />
          <Subscribe />
          <MobileSubscribe />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomePage;

