export const upperArray = [
  {
    url: "https://media.publit.io/file/h_720/2-b6.mp4",
    title: "P2P",
    text: "P2P_story",
  },
  {
    url: "https://media.publit.io/file/h_1080/3-294.mp4",
    title: "NFTM",
    text: "NFTM_story",
  },
  {
    url: "https://media.publit.io/file/h_720/10-H.mp4",
    title: "GW",
    text: "GW_story",
  },

  {
    url: "https://media.publit.io/file/h_720/4-G.mp4",
    title: "DCG",
    text: "DCG_story",
  },
  {
    url: "https://media.publit.io/file/h_720/12-A.mp4",
    title: "FZ",
    text: "FZ_story",
  },
  {
    url: "https://media.publit.io/file/h_720/11-k.mp4",
    title: "DFM",
    text: "DFM_story",
  },
];

export const downArray = [
  {
    url: "https://media.publit.io/file/h_720/21-2T.mp4",
    title: "VR",
    text: "VR_story",
  },
  {
    url: "https://media.publit.io/file/h_720/6-hu.mp4",
    title: "MCS",
    text: "MCS_story",
  },
  {
    url: "https://media.publit.io/file/h_720/5-85e.mp4",
    title: "GS",
    text: "GS_story",
  },


  {
    url: "https://media.publit.io/file/h_720/1-px.mp4",
    title: "MPL",
    text: "MPL_story",
  },
  {
    url: "https://media.publit.io/file/h_720/19-W6.mp4",
    title: "sportage",
    text: "sportage_story",
  },
  {
    url: "https://media.publit.io/file/h_720/16-Ve.mp4",
    title: "KGZ",
    text: "KGZ_story",
  },
  {
    url: "https://media.publit.io/file/h_720/20-l.mp4",
    title: "GP",
    text: "GP_story",
  },
];

export const mobileArray = [
  {
    url: "https://media.publit.io/file/h_720/2-b6.mp4",
    title: "P2P",
    text: "P2P_story",
    path: 'p2p'
  },
  {
    url: "https://media.publit.io/file/h_1080/3-294.mp4",
    title: "NFTM",
    text: "NFTM_story",
    path: 'nft'
  },
  {
    url: "https://media.publit.io/file/h_720/10-H.mp4",
    title: "GW",
    text: "GW_story",
    path: 'gw'
  },
  {
    url: "https://media.publit.io/file/h_720/21-2T.mp4",
    title: "VR",
    text: "VR_story",
    path: 'vr'
  },
  {
    url: "https://media.publit.io/file/h_720/6-hu.mp4",
    title: "MCS",
    text: "MCS_story",
    path: 'mcs'
  },
  {
    url: "https://media.publit.io/file/h_720/1-px.mp4",
    title: "MPL",
    text: "MPL_story",
    path: 'mpls'
  },
  {
    url: "https://media.publit.io/file/h_720/5-85e.mp4",
    title: "GS",
    text: "GS_story",
    path: 'gs'
  },
  {
    url: "https://media.publit.io/file/h_720/4-G.mp4",
    title: "DCG",
    text: "DCG_story",
    path: 'dcg'
  },

  {
    url: "https://media.publit.io/file/h_720/11-k.mp4",
    title: "DFM",
    text: "DFM_story",
    path: 'defi'
  },

  {
    url: "https://media.publit.io/file/h_720/12-A.mp4",
    title: "FZ",
    text: "FZ_story",
    path: 'fz'
  },
  {
    url: "https://media.publit.io/file/h_720/19-W6.mp4",
    title: "sportage",
    text: "sportage_story",
    path: 's'
  },
  {
    url: "https://media.publit.io/file/h_720/16-Ve.mp4",
    title: "KGZ",
    text: "KGZ_story",
    path: 'kgz'
  },
  {
    url: "https://media.publit.io/file/h_720/20-l.mp4",
    title: "GP",
    text: "GP_story",
    path: 'gp'
  },
];
