import React from 'react'
// import { useTranslation } from "react-i18next";
import { firstData,secondData, thirdData } from './sptData';

const styles = {
  container: "w-full flex justify-between space-x-8 px-10 pt-2 mt-4 ",
  textContainer: "w-7/12 space-y-8 flex flex-col h-[56vh] flow-hide",
  parker: "w-full  flex flex-col",
  singleItem: "w-full flex flex-wrap ",
  name: "text-white text-[18px] biome-regular mr-2 ",
  value: "text-yellow  text-[18px] biome-regular",
  imageContainer: "w-5/12 h-full",
  img: "w-full h-auto",
};
const SPT = () => {
  // const {t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.parker}>
          {firstData.map((item, index) => {
            return (
              <div className={styles.singleItem} key={index}>
                <p className={styles.name}>{item.name}</p>
                <p className={styles.value}>{item.value}</p>
              </div>
            );
          })}
      </div>
      <div className={styles.parker}>
        {secondData.map((item, index) => {
          return (
            <div className={styles.singleItem} key={index}>
              <p className={styles.name}>{item.name}</p>
              <p className={styles.value}>{item.value}</p>
              </div>
          );
        })}
      </div>
      <div className={styles.parker}>
        {thirdData.map((item, index) => {
          return (
            <div className={styles.singleItem} key={index}>
              <p className={styles.name}>{item.name}</p>
              <p className={styles.value}>{item.value}</p>
              </div>
          );
        })}
        </div>
        </div>
      <div className={styles.imageContainer}>
        <img
          src="https://media.publit.io/file/mutichain.png"
          alt="piechart"
          className={styles.img}
        />
      </div>
    </div>
    
  );
}

export default SPT

