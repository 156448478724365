import React, { useState, useRef, useEffect } from "react";
import cancel from '../assets/cancel.png';
import AirdropModal from "./Modals/AirdropModal";
import PresaleModal from "./Modals/PresaleModal";
import { useTranslation } from "react-i18next";
import Dropdown from './MobileLang'
import CustomButton from './Button/CustomButton';
import { useHistory } from 'react-router-dom';
import AttentionModal from "./Modals/AttentionModal";
import sound from "../assets/sounds/background-sound.mpeg";
import { AiOutlineAudio, AiOutlineAudioMuted } from "react-icons/ai";



const styles = {
  parentContainer: "w-full m-sticky-nav",
  container: "w-full flex flex-col justify-between items-center biome-regular relative",
  logoContainer: "w-full flex justify-between items-center min-h-[36px]",
  navToggler: "lg:hidden",
  menuCard: "fixed z-[102] bg-blue flex flex-col justify-between top-[64px] left-0 right-0 w-full h-screen transition-all duration-1000 ease-in-out bg-blue",
  menuContainer: "w-full py-10 px-6 flex flex-col gap-9 transition-all duration-1000 ease-in-out",
  menuItem: "text-grey hover:text-white text-md biome-semibold",
  menuItemActive: "text-white text-md biome-semibold",
  homeContainer: "flex flex-col",
  buttonContainer: "flex row-reverse space-x-6 mt-8 relative",
  preBtn: "text-yellow m-preBtn border-yellow border-[1px] px-4 py-2",
  getBtn: "text-white m-getBtn font-bold bg-lightBlue px-4 py-2 mr-4",
};

const MobileNavbar = () => {
  const { t } = useTranslation();
   const audioRef = useRef();
const [openAttention, setOpenAttention] = useState(false);
const [play, setPlay] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPresale, setOpenPresale] = useState(false);
  const router = useHistory()
  const history = useHistory();
  const { hash } = router.location
  const handleClosePresale = () => {
    setOpen(false)
    setOpenPresale(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
    setOpenModal(true)
  }
  const handleCloseAttention = () => {
    setPlay(true);

    setOpenAttention(false)
    
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
    // useEffect(() => {
    //   setOpenAttention(true);
    // }, []);
    // useEffect(() => {
    //   if (play) {
    //     audioRef.current.play();
    //   } else {
    //     audioRef.current.pause();
    //   }
    // }, [play]);
  return (
    <>
      <nav className={styles.parentContainer}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img src={"./logo.svg"} alt="logo" width={118} height={21} />
            <audio
              controls
              loop
              autoPlay
              muted={!play}
              className="opacity-70 w-0"
              ref={audioRef}
            >
              <source src={sound} type="audio/mpeg" />
            </audio>
            <div className="flex space-x-4 items-center">
              {!open && (
                <span
                  className=" w-10 grid place-items-center rounded-full text-white text-xl cursor-pointer"
                  onClick={() => setPlay(!play)}
                >
                  {play ? <AiOutlineAudio /> : <AiOutlineAudioMuted />}
                </span>
              )}
              {open ? (
                <img
                  src={cancel}
                  alt="Close Menu"
                  className={styles}
                  onClick={handleClose}
                  width={"36px"}
                  height={"36px"}
                />
              ) : (
                <img
                  src={"./menu.svg"}
                  alt="menu button"
                  className={styles.navToggler}
                  onClick={handleOpen}
                  width={"36px"}
                  height={"36px"}
                />
              )}
            </div>
          </div>
          {open && (
            <div
              style={{
                maxHeight: "calc(100vh - 64px)",
              }}
              className={styles.menuCard}
            >
              <div className="flex flex-col justify-between h-full pb-20">
                <div className={styles.menuContainer}>
                  <div onClick={handleClose}>
                    <a
                      href="#Home"
                      className={
                        !hash || hash === "#Home"
                          ? styles.menuItemActive
                          : styles.menuItem
                      }
                    >
                      {t("Home")}
                    </a>
                    {(!hash || hash === "#Home") && (
                      <div className="w-6 h-[1px] bg-white" />
                    )}
                  </div>
                  {/* <div onClick={handleClose}>
                    <a
                      href="#Partners"
                      className={
                        hash === "#Partners"
                          ? styles.menuItemActive
                          : styles.menuItem
                      }
                    >
                      {t("Partners")}
                    </a>
                    {hash === "#Partners" && (
                      <div className="w-6 h-[1px] bg-white" />
                    )}
                  </div> */}
                  <div onClick={handleClose}>
                    <a
                      href="#Feature"
                      className={
                        hash === "#Feature"
                          ? styles.menuItemActive
                          : styles.menuItem
                      }
                    >
                      {t("Features")}
                    </a>
                    {hash === "#Feature" && (
                      <div className="w-6 h-[1px] bg-white" />
                    )}
                  </div>
                  <div onClick={handleClose}>
                    <a
                      href="#API"
                      className={
                        hash === "#API"
                          ? styles.menuItemActive
                          : styles.menuItem
                      }
                    >
                      {t("API")}
                    </a>
                    {hash === "#API" && (
                      <div className="w-6 h-[1px] bg-white" />
                    )}
                  </div>
                  <div onClick={handleClose}>
                    <a
                      href="https://media.publit.io/file/SportrexWhitepaper2023.pdf"
                      className={styles.menuItem}
                      target="_blank"
                      download
                      rel="noopener noreferrer"
                    >
                      {t("Whitepaper")}
                    </a>
                  </div>
                  <div>
                    <Dropdown />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-7 max-w-max mx-auto">
                  <CustomButton
                    text={t("Get_Airdrop")}
                    onClick={() => history.push("/get-whitelisted")}
                    small
                  />
                  <CustomButton
                    text={t("Presale")}
                    onClick={handleClosePresale}
                    variant
                    small
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
      <AirdropModal open={openModal} handleClose={() => setOpenModal(false)} />
      <PresaleModal
        open={openPresale}
        handleClose={() => setOpenPresale(false)}
      />
      <AttentionModal open={openAttention} handleClose={handleCloseAttention} />
    </>
  );
};

export default MobileNavbar