import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './index.module.css'
import {BsArrowUpRight} from "react-icons/bs"
const MobileSingleCard = ({ path, text, title }) => {
    const { t } = useTranslation();
    const [flip, setFlip] = useState(false)
    const handleToggle = () => setFlip(prev => !prev)
    return (
      <div className={styles.outer}>
        <div className={`${styles.container}`} onClick={() => handleToggle()}>
          <div className={styles.front}>
            {title === "NFTM" ? (
              <a
                className="w-fit mx-auto text-center absolute top-8   text-white grad-bg-text px-3 py-1 text-[18px] rounded-[20px] mb-4 flex space-x-4 items-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://nftmarketplace.sportrex.io/"
              >
                {" "}
                Get notified
                <span>
                  <BsArrowUpRight className="text-white text-[24px]" />
                </span>
              </a>
            ) : (
              <p className="w-fit mx-auto text-center absolute top-8   text-white grad-bg-text px-3 py-1 text-[18px] rounded-[20px] mb-4">
                {" "}
                Coming soon
              </p>
            )}
            <img
              src={`./assets/img/ecosystem/${path}.png`}
              height={"120px"}
              width={"120px"}
              alt={title}
            />
            <div className="flex justify-between w-full items-center space-x-4">
              <p className={`${styles.title} biome-semibold w-9/12`}>
                {t(`${title}`)}
              </p>
              <div className="block md:hidden mt-4 md:mt-0 ">
                <img
                  src={"./frontside.svg"}
                  alt="Click to view"
                  height={"40px"}
                  width={"40px"}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.back} ${flip ? "block" : "hidden"}`}>
            <p className={`${styles.desc} biome-semibold`}>{t(`${text}`)}</p>
            <div className="flex justify-end">
              <div className="block md:hidden mt-2 md:mt-0   shadow-lg">
                <img
                  src={"./backside.svg"}
                  alt="Go back"
                  height={"40px"}
                  width={"40px"}
                  // className="float-right"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.blue}></div>
      </div>
    );
};

export default MobileSingleCard;