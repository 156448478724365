import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player/lazy";
import ApiModal from "./Modals/ApiModal";
import ColouredHeaderText from "../common/ColouredHeaderText";
import CustomButton from "./Button/CustomButton";

const styles = {
  parentContainer: "w-full relative bg-[#020633] px-4 py-10 lg:py-0 md:px-16 rounded-lg max-w-[1440px] mx-auto",
  container: "w-full md:max-h-max items-center flex-col flex lg:flex-row gap-8 md:gap-24 bg-[#020633]",
  sectionA: "max-w-full md:max-w-587px rounded-[30px] overflow-hidden hidden md:block",
  sectionB: "max-w-[540px]",
  title: "mb-2 md:mb-4",
  story: "text-white leading-[25px] m-story md:text-md xxl:text-lg biome-regular md:leading-9 mb-12",
  imageContainer: "max-h-[498px]",
  btn: "text-white m-getBtn biome-semigold bg-lightBlue py-[1rem] px-[2.4rem] ",
};

const SportrexApi = () => {
  const [openApi, setOpenApi] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpenApi(true);
  }

  return (
    <section className={styles.parentContainer} id="API" style={{ scrollMargin: '6rem' }}>
      <div className={styles.container} style={{ zIndex: "100" }}>
        <div className={styles.sectionA}>
          <ReactPlayer
            url="https://media.publit.io/file/emblema1-0-2.mp4"
            loop={true}
            playing={true}
            playsInline
            muted={true}
            width={'100%'}
            height={'100%'}
          />
        </div>
        <img src={`./assets/img/api.png`} className='block md:hidden' height={"auto"} width={"100%"} alt="sportrex api" />
        <Fade bottom delay={300}>
          <div className={styles.sectionB}>
            <div className={styles.title}>
              <ColouredHeaderText text={t("our_api")} />
            </div>
            <p className={styles.story}>{t("our_api_story")}</p>
            <div className="max-w-max">
              <CustomButton text={t('GAK')} onClick={handleOpen} />
            </div>
          </div>
        </Fade>
      </div>
      <ApiModal open={openApi} handleClose={() => setOpenApi(false)} />
    </section>
  );
};

export default SportrexApi;
