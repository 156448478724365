import React from 'react';
import checkIcon from "../assets/checkIcon.png";

const styles = {
    container: "flex justify-center min-w-max mr-4 sm:mr-0",
    img: "w-4 h-4 mt-2"
}
const CheckIcon = () => {
    return (
        <div className={styles.container}>
            <img src={checkIcon} alt="check icon" className={styles.img} />
        </div>
    )
}

export default CheckIcon