import React, { useState,useRef } from "react";
import Success from "../Feedback/Success";
import Error from "../Feedback/Error";
import { FaTimes } from "react-icons/fa";
import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from "./styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import InfluencerButton from "../Button/InfluencerButton";
import CustomButton from "../Button/CustomButton";
import ReCAPTCHA from "react-google-recaptcha";


const url = "https://sportrex-be.herokuapp.com/api/influencers";

const Influencer = ({ handleClose }) => {
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const initialState = {
    email: '',
    full_name: '',
    about_yourself: '',
    resume: '',
    twitter_link: ''
  }
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
       const recaptcha_token = captchaRef.current.getValue();
       captchaRef.current.reset();
      const { email, full_name, twitter_link, about_yourself, resume } = values
      const response = await axios.post(url, {
        email,
        full_name,
        twitter_link,
        about_yourself,
        resume,
        recaptcha_token,
      });
      if (response.status === (201 || 200)) {
        setSuccess(true)
        setMessage(response.data.message)
        setValues(initialState)
      } else {
        setError(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setError(true);
      setMessage(error.response.data.message);
    }
    setLoading(false)
  };

  const handleChange = (e) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleBack = () => {
    setError(false);
    setSuccess(false)
  }
  return (
    <div className={styles.parentContainer}>
      <div className={styles.headerSession}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>{t("BAI")}</h1>
          <FaTimes className={styles.closeIcon} onClick={handleClose} />
        </div>
      </div>
      {success && <Success message={message} handleBack={handleBack} />}
      {error && <Error message={message} handleBack={handleBack} />}
      {!error && !success && (
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("full_name")}</label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.input}
                placeholder="John Doe"
                value={values.full_name}
                name="full_name"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("email")} </label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                placeholder="johndoe@gmail.com"
                type="email"
                value={values.email}
                name="email"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}> {t("twitter_p")}</label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                placeholder="https://twitter.com/JohnDoe"
                type="text"
                value={values.twitter_link}
                name="twitter_link"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("attach_resume")}</label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                placeholder="John Doe"
                type="file"
                value={values.resume}
                name="resume"
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("tell_about")}</label>
            <div className={styles.inputContainer}>
              <textarea
                className={styles.textArea}
                placeholder="Tell us about yourself"
                value={values.about_yourself}
                name="about_yourself"
                onChange={handleChange}
                required
              />
            </div>
            <ReCAPTCHA
              sitekey="6LejdFMiAAAAADgW68Bowi0slHXTSSmKy9puRGi9"
              ref={captchaRef}
            />
          </div>
          <div className={styles.btnContainer}>
            {loading ? (
              <div className={styles.wait}>
                <p className={styles.waiting}>{t("p_wait")}</p>
                <CircularProgress color="inherit" className="text-white" />
              </div>
            ) : (
              <div role={"button"} type="submit">
                <CustomButton text={t("submit")} onClick={() => {}} />
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Influencer;
