import React from 'react'
// import circle from "../../assets/movingCircle.png";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";

// The sportrex native token, SPT, is minted on multi-chain networks. These includes:
const styles = {
  container: "w-full flex flex-col-reverse space-y-8 ",
  textContainer: "w-full space-y-4 flex flex-col px-4",
  spliterContainer: "w-full flex flex-col space-y-2 ",
  partA: "w-9/12 flex flex-col space-y-2",
  partB: "w-9/12 flex flex-col space-y-2 pb-4",
  name: "text-white text-[14px] biome-regular mt-10",
  value: "text-yellow  text-[14px] biome-regular",
  imageContainer: "w-full  h-full flex justify-center items-center",
  img: "w-10/12 h-auto mx-auto",
  videoContainer: "w-9/12 h-full",
  specialDiv: "w-full flex flex-col space-y-4 pb-4",
  specialDivText: "text-white text-[12px] biome-regular",
  specialContainer: "w-full flex space-x-6 items-center",
  specialImage: "w-16 h-auto",
  specialImageA: "w-24 h-auto",
};
const side1 = [
  "Ethereum Network(ERC20)",
  "Binance Chain (BEP20)",
  "Solana Blockchain",
  "TRON Blockchain",
  

]
const side2 = [
  "Polkadot Network",
  "Cardano Network",
  "Polygon Network(MATIC)",
  "Tezos Blockchain",
  

]
const MultiChain = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.name}>{t("multi_chain_list")}</p>
        <div className={styles.spliterContainer}>
          <div className={styles.partA}>
            {side1.map((item, index) => {
              return (
                <p key={index} className={styles.value}>
                  {item}
                </p>
              );
            })}
          </div>
          <div className={styles.partB}>
            {side2.map((item, index) => {
              return (
                <p key={index} className={styles.value}>
                  {item}
                </p>
              );
            })}
          </div>
        </div>
        <div className={styles.specialDiv}>
          <p className={styles.specialDivText}>Audited By</p>
          <p className="biome-regular text-white text-sm mt-4">
            In Progress...
          </p>
          {/* <div className={styles.specialContainer}>
            <img
              className={styles.specialImage}
              src="https://media.publit.io/file/certix.png "
              alt="circle"
            />
            <img
              className={styles.specialImageA}
              src="https://media.publit.io/file/block-apex.png "
              alt="circle"
            />
          </div> */}
        </div>
      </div>
      <div className={styles.imageContainer}>
        {/* <img src={circle} alt="piechart" className={styles.img} /> */}
        <div className={styles.videoContainer}>
          <ReactPlayer
            url="https://media.publit.io/file/h_720/emblema2-s.mp4"
            loop={true}
            playing={true}
            playsInline
            muted={true}
            height={"auto"}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
}

export default MultiChain