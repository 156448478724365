import React from "react";

// import {CheckIcon} from 'react-icons/fa'
import Fade from 'react-reveal/Fade'
import ReactPlayer from "react-player/lazy";
import CheckIcon from "./CheckIcon";
import { useTranslation } from "react-i18next";
import ColouredHeaderText from "../common/ColouredHeaderText";




const styles = {
  parentContainer: "w-full px-4 py-8 md:py-16 sm:px-16 h-full bg-[#020633] relative max-w-[1440px] mx-auto",
  titleContainer: "w-full mb-6 md:mb-2",
  title: "biome-bold text-2xl gradient-text lg:text-[32px] h-fit leading-10 ",
  imgContainer: "w-full h-full flex justify-center items-center mb-8 md:mb-10",
  itemsContainer: "w-full bg-[#020633] mt-8 md:mt-10 flex flex-col gap-2 md:gap-14 md:flex-row md:justify-between",
  item: "flex items-start md:gap-4",
  icon: "text-white text-xl mt-2",
  text: "biome-regular m-story leading-[29px] md:text-[17px] text-white",
};

const listArray = [
  {
    id: "1",
    icon: CheckIcon,
    story: "SC1",
    delay: 200,
    marginTop: "0rem",
  },
  {
    id: "2",
    icon: CheckIcon,
    story: "SC2",
    delay: 300,
    marginTop: "0rem",
  },
  {
    id: "3",
    icon: CheckIcon,
    story:
      "SC3",
    delay: 400,
    marginTop: "0rem",
  },
];

const SportrexCard = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.parentContainer}>
      <div className={styles.titleContainer}>
        <ColouredHeaderText text={'SportrexCARDS'} />
      </div>
      <div className={styles.imgContainer}>
        <div className={styles.imgParker}>
          <ReactPlayer
            url="https://media.publit.io/file/h_480/Card-p.mp4"
            loop={true}
            playing={true}
            muted={true}
            height={"auto"}
            width={"100%"}
          />
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {listArray.map((item, index) => {
          return (
            <Fade delay={item.delay} key={index} bottom>
              <div
                key={index}
                className={styles.item}
                style={{
                  marginTop: item.marginTop,
                }}
              >
                <CheckIcon />
                <p className={styles.text}> {t(`${item.story}`)}</p>
              </div>
            </Fade>
          );
        })}
      </div>
    </section>
  );
};

export default SportrexCard;
