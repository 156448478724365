import React from "react";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";
import styles from './index.module.css'
import {BsArrowUpRight} from "react-icons/bs"

const SingleCard = ({ url, text, title }) => {
    const { t } = useTranslation();
    return (
      <div className={styles.outer}>
        <div className={`${styles.container} group`}>
          <div className={styles.front}>
            {title === "NFTM" ? (
              <a
                className="w-fit mx-auto text-center absolute top-2   text-white grad-bg-text px-3 py-1 text-[18px] rounded-[20px] mb-4 flex space-x-4 items-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://nftmarketplace.sportrex.io/"
              >
                {" "}
                Get notified
                <span>
                  <BsArrowUpRight className="text-white text-[24px]" />
                </span>
              </a>
            ) : (
              <p className="w-fit mx-auto text-center absolute top-2   text-white grad-bg-text px-3 py-1 text-[18px] rounded-[20px] mb-4">
                {" "}
                Coming soon
              </p>
            )}

            <ReactPlayer
              url={url}
              playsInline
              loop={true}
              playing={true}
              muted={true}
              height={"120px"}
              width={"120px"}
            />

            <p className={`${styles.title} biome-semibold`}>{t(`${title}`)}</p>
            <div className="block md:hidden mt-4 md:mt-0">
              <img
                src={"./frontside.svg"}
                alt="Click to view"
                height={"40px"}
                width={"40px"}
              />
            </div>
          </div>
          <div className={`${styles.back} hidden group-hover:block`}>
            <p className={`${styles.desc} biome-semibold`}>{t(`${text}`)}</p>
            <div className="block md:hidden mt-2 md:mt-0 mx-auto max-w-max">
              <img
                src={"./backside.svg"}
                alt="Go back"
                height={"40px"}
                width={"40px"}
              />
            </div>
            {title === "NFTM" && (
              <a
                className="w-fit mx-auto text-center absolute bottom-2   text-white grad-bg-text px-3 py-1 text-[18px] rounded-[20px] mb-4 flex space-x-4 items-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://nftmarketplace.sportrex.io/"
              >
                {" "}
                Get notified
                <span>
                  <BsArrowUpRight className="text-white text-[24px]" />
                </span>
              </a>
            )}
          </div>
        </div>
        <div className={styles.blue}></div>
      </div>
    );
};

export default SingleCard;

// https://media.publit.io/file/ecosys-shape.png