import React, { useState,useRef,useEffect } from "react";
import { FaBars } from "react-icons/fa";
// import logo from "../assets/primarylogo.png";
import AirdropModal from "./Modals/AirdropModal";
import PresaleModal from "./Modals/PresaleModal";
import Dropdown from "./Drop";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomButton from "./Button/CustomButton";
import sound from "../assets/sounds/background-sound.mpeg";
import { AiOutlineAudio, AiOutlineAudioMuted } from "react-icons/ai";
import AttentionModal from "./Modals/AttentionModal";

const styles = {
  parentContainer: "w-full mb-8 bg-[#020733] border border-[#020733] left-0 sticky-nav bg-blue h-[82px] flex justify-center",
  container: "w-full flex justify-between items-center biome-regular max-w-[1440px]",
  logoContainer: "w-full md:max-w-max flex justify-between items-center",
  navToggler: " text-2xl text-white md:hidden",
  menuContainer: "w-full md:w-6/12 flex ml-4 justify-evenly items-center",
  menuItem: "text-grey hover:text-white text-base xxl:text-md biome-semibold",
  menuItemActive: "text-white text-base xxl:text-md biome-semibold",
  buttonContainer: " md:w-4/12 flex justify-end items-center",
  preBtn: "text-yellow border-yellow border-[1px]  py-[1rem] px-[2.4rem]  biome-semibold ",
  getBtn: "text-white biome-semigold bg-lightBlue  py-[1rem] px-[2.4rem] mr-4 ",
  featureContainer: "relative w-full h-auto flex flex-col",
  featureT: "flex justify-between items-center space-x-4 cursor-pointer text-white text-base md:text-xl biome-semibold",
  listContainer: "absolute top-8  left-0 bg-blue w-48 flex flex-col",
  item: "w-full py-4 text-white hover:bg-semiBlue z-9000",
};

const Navbar = () => {
   const audioRef = useRef();
   
  const [open, setOpen] = useState(false);
  const [play, setPlay] = useState(false);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openPresale, setOpenPresale] = useState(false);
  const router = useHistory();
  const history = useHistory();
  const { hash } = router.location
  const handleClose = () =>  {
    setPlay(true);
    setOpen(false);
  }
  // const handleAirdrop = () => {
  //   setOpenModal(!openModal);
  // }
  const handlePresale = () => {
    setOpenPresale(!openPresale);
  }
  // useEffect(() => {
    
  //   setOpen(true);
  // }, []);
    // useEffect(() => {
    //   if (play) {
    //     audioRef.current.play();
    //   } else {
    //     audioRef.current.pause();
    //   }
    // }, [play]);
  
  return (
    <>
      <nav className={styles.parentContainer}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img
              src={"./logo.svg"}
              alt="logo"
              className={styles.logo}
              width={141}
              height={25}
            />
            <FaBars className={styles.navToggler} />
          </div>
          <div className={styles.menuContainer}>
            <div>
              <a
                href="#Home"
                className={
                  !hash || hash === "#Home"
                    ? styles.menuItemActive
                    : styles.menuItem
                }
              >
                {t("Home")}
              </a>
              {(!hash || hash === "#Home") && (
                <div className="w-6 h-[1px] bg-white" />
              )}
            </div>
            {/* <div>
              <a
                href="#Partners"
                className={
                  hash === "#Partners" ? styles.menuItemActive : styles.menuItem
                }
              >
                {t("Partners")}
              </a>
              {hash === "#Partners" && <div className="w-6 h-[1px] bg-white" />}
            </div> */}
            <div>
              <a
                href="#Features"
                className={
                  hash === "#Features" ? styles.menuItemActive : styles.menuItem
                }
              >
                {t("Features")}
              </a>
              {hash === "#Features" && <div className="w-6 h-[1px] bg-white" />}
            </div>
            <div>
              <a
                href="#API"
                className={
                  hash === "#API" ? styles.menuItemActive : styles.menuItem
                }
              >
                {t("API")}
              </a>
              {hash === "#API" && <div className="w-6 h-[1px] bg-white" />}
            </div>
            <div>
              <a
                href="https://media.publit.io/file/SportrexWhitepaper2023.pdf"
                className={styles.menuItem}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {t("Whitepaper")}
              </a>
            </div>

            <div
              style={{ marginLeft: "2rem" }}
              className="flex space-x-4 items-center"
            >
              <Dropdown />
              <div className="div">
                <span
                  className=" w-10 h-10 grid place-items-center rounded-full text-white text-xl cursor-pointer"
                  onClick={() => setPlay(!play)}
                >
                  {play ? <AiOutlineAudio /> : <AiOutlineAudioMuted />}
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 max-w-max">
            <CustomButton
              text={t("Get_Airdrop")}
              onClick={() => history.push("/get-whitelisted")}
              small
            />
            <CustomButton
              text={t("Presale")}
              onClick={handlePresale}
              variant
              small
            />
          </div>
          <audio
            controls
            loop
            autoPlay
            muted={!play}
            className="opacity-70 w-0"
            ref={audioRef}
          >
            <source src={sound} type="audio/mpeg" />
          </audio>
        </div>
      </nav>
      <AirdropModal open={openModal} handleClose={() => setOpenModal(false)} />
      <PresaleModal
        open={openPresale}
        handleClose={() => setOpenPresale(false)}
      />
      <AttentionModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Navbar;
