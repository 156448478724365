export const firstData = [
  {
    id: "1",
    name: "Token Name:",
    value: "Sportrex Token",
  },
  {
    id: "2",
    name: "Ticker:",
    value: "SPT",
  },
  {
    id: "2",
    name: "Contract Address:",
    value: "0x952bfccdb958ba8521ea206cad099554e55ad2a0",
  },
  {
    id: "2",
    name: "Total Supply:",
    value: "1,000,000,000 SPT",
  },
  {
    id: "2",
    name: "Networks:",
    value: "ERC-20 and BSC (BEP20)",
  },
];
export const secondData = [
  {
    id: "1",
    name: "Token Name:",
    value: "Sportrex Premium NFTs",
  },
  {
    id: "2",
    name: "Ticker:",
    value: "SNFT",
  },
  {
    id: "2",
    name: "Contract Address:",
    value: "00x433c1ac905d0ea5b0ed095721cda2610e62f0365",
  },
  {
    id: "2",
    name: "Total Supply:",
    value: "110,000 SNFT",
  },
  {
    id: "2",
    name: "Network:",
    value: "ERC-721",
  },
];
export const thirdData = [
  {
    id: "1",
    name: "Token Name:",
    value: "Sportrex Virtual Plots",
  },
  {
    id: "2",
    name: "Ticker:",
    value: "SVP",
  },
  {
    id: "2",
    name: "Contract Address:",
    value: "0x87610220e5b814dc36700bf8d93147523aafcef8",
  },
  {
    id: "2",
    name: "Total Supply:",
    value: "1,400,000 SVP",
  },
  {
    id: "2",
    name: "Network:",
    value: "ERC-721",
  },
];
