import React, { useState } from 'react'
import InfluencerModal from '../Modals/InfluencerModal'
import { useTranslation } from "react-i18next";
import InfluencerButton from '../Button/InfluencerButton';




const styles = {
  container: "w-full h-full overflow-y-scroll flow-hide lg:h-full p-4 lg:p-6 flex flex-col space-y-4 ",
  story: "text-black biome-regular m-story-feature md:text-[1.3rem]  md:leading-9 ",
  btn: "  md:w-8/12 bg-lightBlue flex text-[0.9rem] md:text-[1.375rem] items-center biome-semibold justify-center py-4 text-white mb-6 ",
};



const InfluencerProgram = () => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpenModal(true)
  }


  return (
    <div className={styles.container}>
      <p className={styles.story}>{t("IP1")}</p>
      <p className={styles.story}>{t("IP2")}</p>
      <div className="relative flex justify-center sm:justify-start">
        <InfluencerButton action={handleOpen} text="BAI" />
      </div>
      <InfluencerModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </div>
  );
}

export default InfluencerProgram