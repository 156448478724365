import React from 'react'
import RV from '../assets/video/roadmap.mp4'
// import ReactPlayer from "react-player/lazy";
// import roadmap from "../assets/roadmap-temp.png"
import roader from "../assets/video/roadmap-tempo.gif"
const styles = {
  parentContainer: "w-full h-full hidden md:flex flex-col my-32 px-4 sm:px-16 max-w-[1440px] mx-auto bg-blue",
  title: "text-center mb-8 h-full leading-[3rem]  gradient-text text-2xl lg:text-[2.5rem] biome-bold",
  videoContainer: "w-full h-full",
  img: "w-full h-full",
};

const Roadmap = () => {
  return (
    <>
      <section className={styles.parentContainer}>
        {/* <ReactPlayer
          url={RV}
          loop={true}
          playing={true}
          muted={true}
          height={"auto"}
          playsinline
          width={"100%"}
          className={styles.img}
        /> */}
        <img src={roader} alt="roadmap"  />
      </section>
    </>
  );
}

export default Roadmap