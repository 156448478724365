import React from "react";
import SingleCard from "./SingleCard";
import Carousel from "react-elastic-carousel";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Special from "../Special";

// import MobileCard from "./MobileCard";
import { upperArray, downArray, mobileArray } from "./data";
import { useTranslation } from "react-i18next";
import ColouredHeaderText from "../../common/ColouredHeaderText";
import MobileSingleCard from "./MobileSingleCard";
const styles = {
  parentContainer: "w-full h-full flex flex-col my-20 md:my-32 max-w-[1440px] mx-auto px-4 md:px-16",
  sectionA: "w-full h-full flex justify-between mb-10",
  title: "biome-bold text-2xl gradient-text lg:text-[35px] leading-10 h-fit",
  actions: "flex items-center gap-6",
  arrow: "flex-center rounded-full min-w-max h-10 w-10 bg-grey hover:bg-lightBlue cursor-pointer smooth",
  container: "w-full h-full",
  sectionB: "hidden lg:flex lg:w-full overflow-x-hidden flex flex-col space-y-6",
  sectionC: "w-full lg:hidden overflow-x-hidden  flex flex-col",
  cardContainer: "w-full flex space-x-4 min-w-full",
};

const TestingMode = () => {
  const { t } = useTranslation();
  let carousel;
  let mobileCarousel;
  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 600, itemsToShow: 2 },
    { width: 760, itemsToShow: 3 },
    { width: 1024, itemsToShow: 3 },
    { width: 1279, itemsToShow: 4 },
    { width: 1320, itemsToShow: 5 },
  ];
  const bothArrays = upperArray.map((item, i) => [item, downArray[i]])

  return (
    <section className={styles.parentContainer}>
      <div className={styles.sectionA}>
        <div className={styles.title}>
          <ColouredHeaderText text={t("sportrex_ecosystem")} />
        </div>
        <div className="sm:block hidden">
          <div className={styles.actions}>
            <div className={styles.arrow} onClick={() => carousel.slidePrev()}>
              <ArrowBackIcon fontSize='small' />
            </div>
            <div className={styles.arrow} onClick={() => carousel.slideNext()}>
              <ArrowForwardIcon fontSize='small' />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.sectionB}>
          <Carousel renderArrow={null} breakPoints={breakPoints} pagination={false} ref={ref => carousel = ref}>
            {bothArrays.map((array, i) => (
              <div
                key={i}
                className={`flex items-center flex-col gap-[27px] min-w-full px-[14px] ${i === 0 && "pl-0"}`}
              >
                <SingleCard url={array[0].url} text={array[0].text} title={array[0].title} />
                <SingleCard url={array[1].url} text={array[1].text} title={array[1].title} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={styles.sectionC}>
          <Carousel breakPoints={breakPoints} pagination={false} ref={ref => mobileCarousel = ref}>
            {mobileArray.map((item, index) => {
              const { text, title, path } = item;
              return (
                <MobileSingleCard key={index} text={text} title={title} path={path} />
              );
            })}
          </Carousel>
          <div className="max-w-max mx-auto mt-4">
            <div className={styles.actions}>
              <div className={styles.arrow} onClick={() => mobileCarousel.slidePrev()}>
                <ArrowBackIcon fontSize='small' />
              </div>
              <div className={styles.arrow} onClick={() => mobileCarousel.slideNext()}>
                <ArrowForwardIcon fontSize='small' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestingMode;
