import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import ColouredHeaderText from "../common/ColouredHeaderText";
import CheckIcon from './CheckIcon';
import google from "../assets/sounds/google.svg"
import apple from "../assets/sounds/apple.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";

const styles = {
  parentContainer: "w-full h-full px-4 sm:px-16 mb-8 lg:mt-32 lg:mb-16 relative max-w-[1440px] mx-auto",
  mainContainer: "w-full h-full",
  title: "text-[22px] lg:text-[35px] gradient-text biome-bold mb-6 md:mb-10",
  flexContainer: "w-full h-full flex flex-col md:flex-row gap-8 md:gap-14",
  sectionA: "md:-ml-8 md:min-w-[385px] md:max-w-[385px] md:max-h-[465px] w-full h-full",
  sectionB: "w-full min-h-full flex flex-col justify-between md:gap-8 md:pt-8",
  sectionList: "w-full flex flex-col lg:flex-row md:gap-20 md:mb-5",
  item: "max-w-[357px] flex items-start leading-9 gap-1.5 md:gap-5",
  text: "biome-regular m-story leading-[25px] md:leading-9 md:text-[16px] text-white",
  downloadContainer: "max-w-max flex justify-between gap-6 mt-4 md:mt-0",
  downloadItem: "w-[130px] md:w-[170px] min-h-max ",
  downloadImg: "w-full h-auto cursor-pointer",
  imgContainer: "w-full lg:w-11/12 h-full element-index",
  img: "",
};

const GamersWallet = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.parentContainer}>
      <div className={styles.mainContainer} style={{ zIndex: "2000" }}>
        <Fade top>
          <div className={styles.title}>
            <ColouredHeaderText text={t("gamers_wallet")} />
          </div>
        </Fade>
        <div className={styles.flexContainer}>
          <Fade left>
            <div className={styles.sectionA}>
              <LazyLoadImage
                src="https://media.publit.io/file/GAMERSWALLET-NEW-ANIMEE1.gif"
                className={styles.img}
                alt="iphone"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className={styles.sectionB}>
              <div>
                <div className={styles.sectionList}>
                  <Fade bottom delay={100}>
                    <div className={styles.item}>
                      <CheckIcon />
                      <p className={styles.text}>{t("GW1")}</p>
                    </div>
                  </Fade>
                  <Fade bottom delay={200}>
                    <div className={styles.item}>
                      <CheckIcon />
                      <p className={styles.text}>{t("GW2")}</p>
                    </div>
                  </Fade>
                </div>
                <div className="w-full justify-start">
                  <Fade bottom delay={300}>
                    <div className={styles.item}>
                      <CheckIcon />
                      <p className={styles.text}>{t("GW3")}</p>
                    </div>
                  </Fade>
                </div>
              </div>
              <Fade bottom delay={400}>
                <div className={styles.downloadContainer}>
                  <div className={styles.downloadItem}>
                    <img
                      src={apple}
                      alt="download"
                      className={styles.downloadImg}
                    />
                  </div>
                  <div className={styles.downloadItem}>
                    <img
                      src={google}
                      alt="download"
                      className={styles.downloadImg}
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </Fade>
        </div>
      </div>
      <div className="blur-circle-purple bg-index" />
    </section>
  );
};

export default GamersWallet;


