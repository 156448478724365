import React, {useState,useRef } from "react";
import Success from "../Feedback/Success";
import Error from "../Feedback/Error";
import { FaTimes } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styles } from "./styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomButton from "../Button/CustomButton";
import { socials } from "../../constants";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReCAPTCHA from "react-google-recaptcha";


const url =
  "https://sportrex-webiste-api-fb47fa67972d.herokuapp.com/api/v1/sportrex/airdrop";

const copy = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.33398 5.3335H2.66732C1.93198 5.3335 1.33398 5.9315 1.33398 6.66683V13.3335C1.33398 14.0688 1.93198 14.6668 2.66732 14.6668H9.33398C10.0693 14.6668 10.6673 14.0688 10.6673 13.3335V6.66683C10.6673 5.9315 10.0693 5.3335 9.33398 5.3335Z" fill="white" />
  <path d="M13.334 1.3335H6.66732C6.3137 1.3335 5.97456 1.47397 5.72451 1.72402C5.47446 1.97407 5.33398 2.31321 5.33398 2.66683V4.00016H10.6673C11.0209 4.00016 11.3601 4.14064 11.6101 4.39069C11.8602 4.64074 12.0007 4.97987 12.0007 5.3335V10.6668H13.334C13.6876 10.6668 14.0267 10.5264 14.2768 10.2763C14.5268 10.0263 14.6673 9.68712 14.6673 9.3335V2.66683C14.6673 2.31321 14.5268 1.97407 14.2768 1.72402C14.0267 1.47397 13.6876 1.3335 13.334 1.3335Z" fill="white" />
</svg>


const Presale = ({ handleClose }) => {
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  const initialState = {
    spt_address: '',
  }
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      // setTimeout(() => {
      //   setSuccess(true);
      //   setMessage("Congratulations you have successfully joined the waitlist");
      //   setValues(initialState);
      //   setLoading(false)
      // }, 3000);
    try {
      const recaptcha_token = captchaRef.current.getValue();
     captchaRef.current.reset();
     
      const { spt_address } = values
      const response = await axios.post(url, {
        address: spt_address,
        // recaptcha_token,
      });
      if (response.data?.success) {
        setSuccess(true)
        setMessage("Congratulations you have successfully joined the waitlist");
        setValues(initialState)
      } else {
        setError(true)
        setMessage(response.data.error)
      }
    } catch (error) {
      setError(true);
      setMessage(error.response?.data?.error);
    }
    setLoading(false)
  };

  const handleChange = (e) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleBack = () => {
    setError(false);
    setSuccess(false)
  }
  const handleCopy = () => {
    setShowCopyMessage(() => true)
  }
  return (
    <div className={styles.parentContainer}>
      <div className={styles.headerSession}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}> {t("Presale")}</h1>
          <FaTimes className={styles.closeIcon} onClick={handleClose} />
        </div>
      </div>
      {success && <Success message={message} handleBack={handleBack} />}
      {error && <Error message={message} handleBack={handleBack} />}
      {!error && !success && (
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.socials}>
            {socials?.map((social) => (
              <div key={social.key} className="mb-10">
                <h4 className={styles.socialTitle}>{social.title}</h4>
                <div className="flex items-center gap-4">
                  <img
                    src={`/assets/icons/${social.key}.png`}
                    width={32}
                    height={32}
                    alt={social.key + " icon"}
                  />
                  <a
                    className="text-white font-[600]"
                    href={social.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {social.link}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}> {t("spt_address")}</label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.input}
                placeholder="0x061b4a25BE391Aa2034fC7aC5b631B1066423259"
                value={values.spt_address}
                onChange={handleChange}
                required
                name="spt_address"
              />
            </div>
            <ReCAPTCHA
              sitekey="6LejdFMiAAAAADgW68Bowi0slHXTSSmKy9puRGi9"
              ref={captchaRef}
            />
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}>
              Refer your friends to join the community
            </label>
            <div className="flex items-center gap-3">
              <p className="text-white font-[600]">https://www.sportrex.io</p>
              <CopyToClipboard
                text={"https://www.sportrex.io"}
                onCopy={() => handleCopy()}
              >
                <span className="cursor-pointer">{copy}</span>
              </CopyToClipboard>
              {showCopyMessage && <p className="text-green">copied!</p>}
            </div>
          </div>

          <div className={styles.btnContainer}>
            {loading ? (
              <div className={styles.wait}>
                <p className={styles.waiting}>{t("p_wait")}</p>
                <CircularProgress color="inherit" className="text-white" />
              </div>
            ) : (
              <div role={"button"} type="submit">
                <CustomButton text={"Submit"} onClick={() => {}} />
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Presale;
