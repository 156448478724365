import React from 'react'
// import allstar from '../assets/allstar.png'
import { useTranslation } from "react-i18next";
import { Fade } from 'react-reveal';
import ColouredHeaderText from '../common/ColouredHeaderText';

const styles = {
  container: "w-full px-4 sm:px-16 lg:flex-row flex-col flex justify-between items-center max-w-[1440px] mx-auto",
  textContainer: "max-w-[375px] mb-10 md:mb-0",
  text: "biome-bold text-center md:text-start text-2xl gradient-text text-start mt-8 lg:text-[35px] text-white w-full  lg:w-full leading-[3rem] h-fit",
  imageContainer: "w-full lg:w-8/12 flex justify-center",

  img: "w-11/12 h-auto lg:w-full lg:h-full",
};

const ListComponent = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <Fade bottom delay={100}>
        <div className={styles.textContainer}>
          <ColouredHeaderText text={t("SPL")} />
        </div>
      </Fade>
      <div className={styles.imageContainer}>
        <img
          src="https://media.publit.io/file/listings-on.png"
          className={styles.img} alt="Listing .."
        />
      </div>
    </section>
  );
}

export default ListComponent
