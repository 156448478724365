import React, { useState } from 'react'
import FeatureGame from './FeatureGame'
import FeatureSupport from './FeatureSupport'
import FeatureLeague from './FeaturedLeague'
import InfluencerProgram from './InfluencerProgram'
import { useTranslation } from "react-i18next";

const styles = {
  parentContainer: "hidden max-w-[1440px] mx-auto lg:block w-full px-4 sm:px-16 h-full relative biome-regular",
  container: "w-full h-full flex items-center",
  sectionA: " bg-white w-7/12 min-h-[619px] h-full overflow-y-scroll flow-hide ",
  sectionB: " w-5/12 h-[fit-content]  flex items-center bg-semiBlue  ",
  tabsContainer: "w-full flex flex-col  ",
  activeTab: "bg-lightBlue px-6 lg:px-12 text-white text-bold biome-bold py-4 lg:py-11 text-[28px] cursor-pointer",
  inactiveTab: "text-grey lg:py-11 py-4 px-4 lg:px-12 text-[28px] cursor-pointer biome-bold",
  img: "w-full h-auto element-index",
  tab: "w-full"
};

const Feature = ({ ref }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("FG");
  return (
    <section className={styles.parentContainer} id="Features" ref={ref} style={{ scrollMargin: '6rem' }}>
      <div className="blur-circle bg-index" />
      <div
        className={styles.container}
        style={{ zIndex: "100000" }}
      >
        <div className={styles.sectionA}>
          {selected === "FG" && <FeatureGame />}
          {selected === "FL" && <FeatureLeague />}
          {selected === "PS" && <FeatureSupport />}
          {selected === "IP" && <InfluencerProgram />}
        </div>
        <div className={styles.sectionB}>
          <div className={styles.tabsContainer}>
            <div
              className={`${styles.tab} ${selected === "FG" ? styles.activeTab : styles.inactiveTab
                }`}
              onClick={() => setSelected("FG")}
            >
              {t("FG")}
            </div>
            <div
              className={`${styles.tab} ${selected === "FL" ? styles.activeTab : styles.inactiveTab
                }`}
              onClick={() => setSelected("FL")}
            >
              {t("FL")}
            </div>
            <div
              className={`${styles.tab} ${selected === "PS" ? styles.activeTab : styles.inactiveTab
                }`}
              onClick={() => setSelected("PS")}
            >
              {t("PS")}
            </div>
            <div
              className={`${styles.tab} ${selected === "IP" ? styles.activeTab : styles.inactiveTab
                }`}
              onClick={() => setSelected("IP")}
            >
              {t("IP")}
            </div>
          </div>
        </div>
      </div>
      <div className="blur-circle-purple-r bg-index" />
    </section>
  );
}

export default Feature