import React,{useState} from 'react'
import CustomButton from "../components/Button/CustomButton/CustomButton2"
import NormalLayout from '../Layouts/NormalLayout';
import { Link } from 'react-router-dom';
import telegram from "../assets/icons/telegram.png";
import twitter from "../assets/icons/twitter.png";
import instagram from "../assets/icons/instagram.png";
import discord from "../assets/icons/discord.png";
import twitch from "../assets/icons/twitch.png";
import youtube from "../assets/icons/youtube.png";
import medium from "../assets/icons/medium.png";
import tiktok from "../assets/icons/tiktok.png";
import { FaCopyright } from 'react-icons/fa';
import AirdropModal from "../components/Modals/AirdropModal";

import "./white-listed.css"

const linksArrayA = [
  {
    id: "1",
    link: "https://t.me/sportrexofficial",
    icon: telegram,
    name: "telegram",
  },
  {
    id: "2",
    link: "https://twitter.com/sportrexhq",
    icon: twitter,
    name: "twitter",
  },
  {
    id: "3",
    link: "https://instagram.com/sportrexofficial",
    icon: instagram,
    name: "instagram",
  },
  {
    id: "4",
    link: "https://discord.gg/QMqmznUvq2",
    icon: discord,
    name: "discord",
  },
  {
    id: "5",
    link: "https://www.twitch.tv/sportrexofficial",
    icon: twitch,
    name: "twitch",
  },
  {
    id: "6",
    link: "https://medium.com/@sportrex",
    icon: medium,
    name: "medium",
  },
  {
    id: "7",
    link: "https://www.youtube.com/channel/UCD-fhKSFYxU4em8mouCunEA",
    icon: youtube,
    name: "twitch",
  },

  {
    id: "8",
    link: "https://vm.tiktok.com/ZM8KVx1gb/",
    icon: tiktok,
    name: "tiktok",
  },
];
const GetWhiteListed = () => {
 
  const [openModal, setOpenModal] = useState(false);
    const handleAirdrop = () => {
      setOpenModal(!openModal);
    };
  return (
    <div className="2xl:container 2xl:mx-auto max-w-[1440px] mx-auto flow-hide h-screen relative overflow-x-hidden">
      <NormalLayout>
        <div className="flex justify-between w-full items-center mt-10 toper">
          <div className="w-[181px] h-auto ">
            {/* <img src={logo} alt="logo" className="w-full h-auto" /> */}
            <Link to="/home">
              <img
                src={"./logo.svg"}
                alt="logo"
                className="w-[140px] lg:w-[200px]"
                height={21}
              />
            </Link>
          </div>

          <div className="flex space-x-8 items-center toper">
            <a
              href="https://media.publit.io/file/SPORTREX-WHITE-PAPER.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#f1f1f1] hover:text-white regular cursor-pointer"
            >
              Whitepaper
            </a>
            <div className="hidden md:flex toper">
              <CustomButton text="Get whitelisted" onClick={handleAirdrop} />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10  md:mt-10">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="left w-full sm:w-9/12 md:w-5/12 flex flex-col justify-center">
              <h1 className="text-[#f1f1f1] biome-bold text-[32px] sm:text-[36px] xl:text-[40px] 2xl:text-[48px]   2xl:w-10/12 leading-[42px]  md:leading-[56px]">
                Sign up for the Sportrex token sale
              </h1>
              <p className="mt-4 text-[#f1f1f1] text-base md:text-[18px] semibold w-full  lg:w-9/12 mb-8 biome-semibold">
                We’re launching Sportrex Token, the native token which serves as
                the governance, rewards and utility token for the{" "}
                <span className="text-[#fac744]">
                  <Link to="/home">Sportrex.io</Link>{" "}
                </span>
                ecosystem.
              </p>
              <div className="w-fit ">
                <CustomButton text="Get whitelisted" onClick={handleAirdrop} />
              </div>
              <div className="flex items-center w-full mt-6 space-x-4">
                <img
                  src={"./ens.svg"}
                  alt="logo"
                  className="w-[80px] sm:w-[90px] 2xl:w-[121px] h-auto "
                />
                <p className="text-[10px] md:text-sm biome-regular text-white">
                  Sign in with your ENS
                </p>
              </div>
            </div>
            <div className="right w-full md:w-7/12 relative hidden md:flex">
              <div className="blur-list-right"></div>
              <img
                src={"./light-4.svg"}
                alt="logo"
                className="w-full h-auto mt-6 z-star"
              />
            </div>
          </div>
          {/* end of one */}
          <div className="flex flex-col-reverse md:flex-row-reverse md:space-x-8 mt-10 md:mt-20">
            <div className="left w-full md:w-5/12 flex flex-col justify-center mt-10 md:mt-0">
              <h1 className="text-[#f1f1f1] biome-bold text-[32px] sm:text-[36px] xl:text-[40px] 2xl:text-[48px]  2xl:w-10/12 leading-[42px] md:leading-[56px]">
                How & Where to buy SPT token:
              </h1>
              <p className="mt-4 text-[#f1f1f1] text-base md:text-[18px] biome-semibold md:w-10/12 ">
                Sportrex will launch the SPT token listing on major CEXs and
                DEXs, coming soon. To participate in the Private-sale, Presale,
                IGO (Initial Gaming Offering) and our premium NFT collections,
                you must sign up and get verified. You’ll be able to buy using
                BUSD, USDT, USDC respectively.
              </p>

              <div className="w-fit mt-6">
                <CustomButton text="Get whitelisted" onClick={handleAirdrop} />
                
              </div>
              <div className="flex items-center w-full mt-6 space-x-4">
                <img
                  src={"./ens.svg"}
                  alt="logo"
                  className="w-[80px] sm:w-[90px] 2xl:w-[121px] h-auto "
                />
                <p className="text-[10px] md:text-sm biome-regular text-white mt-4">
                  Sign in with your ENS
                </p>
              </div>
            </div>
            <div className="right w-full md:w-7/12 relative">
              <div className="blur-list-left"></div>
              <img
                src={"./light-3.svg"}
                alt="logo"
                className="w-full h-auto mt-6 xl:w-8/12 z-star"
              />
            </div>
          </div>
          {/* end of one */}
          <div className="w-full rounded-[20px] bg-[#3333ff] flex py-10 mt-20">
            <div className="w-11/12 flex flex-col md:flex-row mx-auto justify-between items-start">
              <h1 className="text-[#f1f1f1] text-[24px] mb-4 md:mb-0 md:text-[32px] 2xl:text-[36px] biome-bold">
                Disclaimer:
              </h1>
              <div className="w-full md:w-9/12">
                <p className="text-[#f1f1f1] text-sm biome-regular text-justify">
                  The information shown is provided for your information only
                  and does not constitute investment advice. SPORTREX tokens
                  (SPT) are governance, rewards and utility tokens designed for
                  use on{" "}
                  <span>
                    <Link to="/home">Sportrex.io</Link>{" "}
                  </span>
                  . SPORTREX tokens (SPT) are not shares, bonds, units in a
                  collective investment scheme, securities, or other instruments
                  commonly known as securities of any type. SPORTREX tokens
                  (SPT) are offered only as decentralised token offerings and
                  are only available on major CEXs/DEXs that are officially
                  licensed and regulated within their operating jurisdictions,
                  as may be specified by the exchanges where SPT tokens is
                  listed, from time to time.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 mb-6">
            <div className="flex flex-col items-center mt-4 ">
              <h1 className="text-white text-[14px] md:text-[20px] text-start biome-bold">
                Join the Sportrex community
              </h1>
              <div className="flex space-x-2 justify-center mt-4">
                {linksArrayA.map((item, index) => {
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      key={index}
                      rel="noopener noreferrer"
                    >
                      <abbr title={`${item.name}`}>
                        <img
                          src={item.icon}
                          alt="icons"
                          className="w-8 md:w-10 h-auto"
                        />
                      </abbr>
                    </a>
                  );
                })}
              </div>
              <div className="mt-16 flex space-x-2 items-center justify-start">
                <FaCopyright className="text-[#999] regular text-[14px]" />
                <p className="text-[#fff] biome-regular text-[14px]">
                  2022 Sportrex Inc. All right reserved
                </p>
              </div>
            </div>
          </div>
        </div>
        <AirdropModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      </NormalLayout>
    </div>
  );
}

export default GetWhiteListed