import React, { useState } from "react";
import AirdropModal from "./Modals/AirdropModal";
import PresaleModal from "./Modals/PresaleModal";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player/lazy";
import CustomButton from "./Button/CustomButton";
import ColouredHeaderText from "../common/ColouredHeaderText";
import { useHistory } from "react-router-dom";
// import LazyLoad from "react-lazy-load";
import { useInView } from "react-intersection-observer";

const styles = {
  parentContainer:
    "w-full relative flex biome-regular px-4 sm:px-16 my-20 md:my-32 md:max-h-max max-w-[1440px] mx-auto",
  title:
    "m-title biome-bold md:text-xl xxl:text-2xl md:mb-4 gradient-text md:leading-[3rem] font-bols",
  container:
    "w-full overflow-hidden rounded-[30px] flex justify-between align-center flex-col lg:flex-row bg-semiBlue text-white md:gap-[52px]",
  story:
    " m-story text-white text-base md:text-md xxl:text-[22px] biome-regular md:leading-9",
  storyContainer:
    "max-w-[624px] pb-12 pt-6 px-4 md:pt-0 md:pr-12 md:pl-0 md:pb-0 h-full flex flex-col justify-center",
  imgContainer: "hidden md:block",
};

const SportrexVerse = () => {
  const { t } = useTranslation();
  // const handleAirdrop = () => {
  //   setOpenModal(!openModal);
  // };
  const handlePresale = () => {
    setOpenPresale(!openPresale);
  };
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openPresale, setOpenPresale] = useState(false);
  const { ref, inView } = useInView();
  return (
    <section className={styles.parentContainer}>
      <div
        className={styles.container}
        style={{
          zIndex: "100",
        }}
      >
        <div className={styles.imgContainer} ref={ref}>
          {inView && (
            <ReactPlayer
              url="https://media.publit.io/file/h_720/human-w.mp4"
              controls={false}
              loop={true}
              playing={true}
              muted={true}
              playsInline
              width={"100%"}
              height={"100%"}
            />
          )}
        </div>
        <img
          src={`./assets/img/sportverse.png`}
          className="block md:hidden"
          height={"auto"}
          width={"100%"}
          alt="sportverse"
        />
        <div className={styles.storyContainer}>
          <Fade delay={100} top>
            <div className={styles.title}>
              <ColouredHeaderText text={t("sportrex_verse")} />
            </div>
          </Fade>
          <Fade delay={400} bottom>
            <p className={styles.story}>{t("sportrex_verse_story")}</p>
          </Fade>
          <Fade bottom delay={900}>
            <div className="grid grid-cols-2 gap-7 md:gap-[47px] max-w-max mt-6 md:mt-8">
              <CustomButton
                text={t("Get_Airdrop")}
                onClick={() => history.push("/get-whitelisted")}
              />
              <CustomButton
                text={t("Presale")}
                onClick={handlePresale}
                variant
              />
            </div>
          </Fade>
        </div>
      </div>
      <AirdropModal open={openModal} handleClose={() => setOpenModal(false)} />
      <PresaleModal
        open={openPresale}
        handleClose={() => setOpenPresale(false)}
      />
    </section>
  );
};

export default SportrexVerse;
