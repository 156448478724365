import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import logo from "../../assets/sounds/smiley.svg"
// import Api from "./Api";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
        alignItems: "center",
        border: "none !important",
        outline: "none !important",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
    justifyContent: "center",
  },
}));
const styles = {
  container:
    "w-full  lg:w-5/12 h-auto max-w-lg lg:h-fit  mx-auto rounded-lg  flex md:items-center items-center self-center",
};

export default function AttentionModal({ open, handleClose, }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          border: "none !important",
          outline: "none !important",
          borderWidth: "0px !important",
        }}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.container}>
            <div className="w-full px-2 py-4 md:p-8 flex flex-col items-center space-y-6 rounded-lg bg-blue">
              <img src={logo} alt="sportrex" />
              <h1 className="biome-semibold text-white text-center hidden md:block">
                Welcome onboard! <br /> Vibe with us while surfing through our
                webpage. <br />
                Kindly press mute button if you like it quiet <br />
                <span className="text-[10px]">🎵 🎼 💃 💃 🕺🏽 🕺🏽</span>
              </h1>
              <h1 className="biome-semibold text-white text-center text-[15px] md:hidden tracking-wide ">
                Welcome onboard! <br /> Vibe with us while surfing through our
                webpage. <br />
                Kindly press mute button if you like it quiet <br />
                <span className="text-[10px]">🎵 🎼 💃 💃 🕺🏽 🕺🏽</span>
              </h1>
              <p
                className="bg-lightBlue px-4 py-1 text-white text-md biome-bold rounded-sm"
                onClick={handleClose}
              >
                OK
              </p>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
