import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfluencerProgram from './InfluencerProgram';
import FeatureSupport from './FeatureSupport';
import FeatureGame from './FeatureGame';
import FeaturedLeague from './FeaturedLeague';

const Accordion = withStyles({
  root: {
    backgroundColor: '#0E1648',
    border: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: '0px',
    padding: '0',
    '&:before': {
      display: 'none',
    },
    '&:last-of-type': {
      marginBottom: '0px'
    },
    '&$expanded': {
      margin: 0,
      marginBottom: '0px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#0E1648',
    color: '#ABABAB',
    padding: '0px 16px',
    minHeight: 56,
    transition: 'all 0.3s ease-linear',
    '&$expanded': {
      minHeight: 56,
      backgroundColor: '#3333FF',
      color: '#FFFFFF'
    },
  },
  content: {
    margin: '20px 0',
    '&$expanded': {
      margin: '20px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    color: '#FFFFFF',
    backgroundColor: '#FFFFFF'
  },
}))(MuiAccordionDetails);

const MobileFeatures = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="Feature" className='sm:hidden px-4 mb-20' style={{ scrollMargin: '6rem' }}>
      <div className="mb-4 flex justify-center">
      </div>
      <div>
        <Accordion square expanded={expanded === `feature1`} onChange={handleChange(`feature1`)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className='text-grey' />}
            aria-controls={`feature1-content`}
            id={`feature1`}
          >
            <p className='biome-bold'>
              Featured Leagues
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <FeaturedLeague />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === `feature2`} onChange={handleChange(`feature2`)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className='text-grey' />}
            aria-controls={`feature2-content`}
            id={`feature2`}
          >
            <p className='biome-bold'>
              Featured Games
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <FeatureGame />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === `feature3`} onChange={handleChange(`feature3`)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className='text-grey' />}
            aria-controls={`feature3-content`}
            id={`feature3`}
          >
            <p className='biome-bold'>
              Featured Support
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <FeatureSupport />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === `feature4`} onChange={handleChange(`feature4`)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className='text-grey' />}
            aria-controls={`feature4-content`}
            id={`feature4`}
          >
            <p className='biome-bold'>
              Influencer Program
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <InfluencerProgram />
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}

export default MobileFeatures;