import React from 'react'
// import circle from "../../assets/movingCircle.png";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";



const styles = {
  container: "w-full flex justify-between space-x-8 px-10 pt-2 pb-4 mt-2 ",
  textContainer: "w-7/12 space-y-4 flex flex-col",
  spliterContainer: "w-full flex space-x-6",
  partA: "w-7/12 flex flex-col space-y-2 2xl:space-y-4 ",
  partB: "w-5/12 flex flex-col space-y-2 2xl:space-y-4",
  name: "text-white text-[18px] biome-regular mt-4",
  value: "text-yellow  text-[18px] biome-regular",
  videoContainer: "w-9/12 h-full",
  imageContainer: "w-5/12 h-full flex justify-center items-center",
  specialDiv: "w-full flex flex-col space-y-0 pt-8 lg:pb-4",
  specialDivText: "text-white text-[14px] 2xl:text-[18px] biome-regular",
  specialContainer: "w-full flex space-x-6 items-center",
  specialImage: "w-20 h-auto",
  specialImageA: "w-28 h-auto",
  img: "w-10/12 h-auto",
};
const side1 = [
  "Ethereum Network (ERC20)",
  "Binance Chain (BEP20)",
  "Solana Blockchain",
  "TRON Blockchain",
]

const side2 = [
  "Polkadot Network",
  "Cardano Network",
  "Polygon Network (MATIC)",
  "Tezos Blockchain",
]
const MultiChain = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.name}>{t("multi_chain_list")}</p>
        <div className={styles.spliterContainer}>
          <div className={styles.partA}>
            {side1.map((item, index) => {
              return (
                // <div className="flex space-x-4 items-center" key={index}>
                    // <img src="/ether2.png" alt="dhdsd" className='w-8 h-auto' />
                <p key={index} className={styles.value}>
                  {item}
                </p>
                // </div>
              );
            })}
          </div>
          <div className={styles.partB}>
            {side2.map((item, index) => {
              return (
                // <div className="flex space-x-4 items-center" key={index}>
                  // <img src="/binance.png" alt="dhdsd" className="w-8 h-auto" />

                  <p key={index} className={styles.value}>
                    {item}
                  </p>
                // </div>
              );
            })}
          </div>
        </div>
        <div className={styles.specialDiv}>
          <p className={styles.specialDivText}>Audited By</p>
          <p className="biome-regular text-base text-white mt-4">
            In Progress...
          </p>
          {/* <div className={styles.specialContainer}>
            <img className={styles.specialImage} src="https://media.publit.io/file/certix.png " alt="circle" />
            <img className={styles.specialImageA} src="/block-apex.svg " alt="circle" />
      </div> */}
      </div>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.videoContainer}>
          <ReactPlayer
            url="https://media.publit.io/file/h_720/emblema2-s.mp4"
            loop={true}
            playing={true}
            playsInline
            muted={true}
            height={"auto"}
            width={"100%"}
          />
        </div>
      </div>
    
    </div>
  );
}

export default MultiChain