import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import telegram from "../assets/icons/telegram.png";
import twitter from "../assets/icons/twitter.png";
import instagram from "../assets/icons/instagram.png";
import discord from "../assets/icons/discord.png";
import twitch from "../assets/icons/twitch.png";
import AirdropModal from "./Modals/AirdropModal";
import PresaleModal from "./Modals/PresaleModal";
import ColouredHeaderText from "../common/ColouredHeaderText";
import CustomButton from "./Button/CustomButton";
import blurImageUrl from 'blur-image-url';
import LazyImage from 'react-lazy-blur-image';
import { useHistory } from "react-router-dom";
import linkedin from "../assets/icons/linkedin-img.png";

const linksArray = [
  {
    id: "1",
    link: "https://t.me/sportrexofficial",
    icon: telegram,
    name: "telegram",
  },
  {
    id: "2",
    link: "https://twitter.com/sportrexhq",
    icon: twitter,
    name: "twitter",
  },
  {
    id: "3",
    link: "https://instagram.com/sportrexofficial",
    icon: instagram,
    name: "instagram",
  },
  {
    id: "4",
    link: " https://discord.gg/QMqmznUvq2",
    icon: discord,
    name: "discord",
  },
  {
    id: "5",
    link: "https://www.twitch.tv/sportrexofficial",
    icon: twitch,
    name: "twitch",
  },
  {
    id: "6",
    link: "https://www.linkedin.com/company/sportrexofficial/",
    icon: linkedin,
    name: "linkedin",
  },
];
const imagesArray = [
  "https://media.publit.io/file/image-and-lights-2.png",
  "https://media.publit.io/file/image-and-lights.png",
  "https://media.publit.io/file/image-and-lights-6.png",
  "https://media.publit.io/file/image-and-lights-3.png",
  "https://media.publit.io/file/image-and-lights-4.png",
];

const styles = {
  mainContainer: "pt-[64px] md:pt-[102px] max-w-[1440px] mx-auto px-2 md:px-16 w-full",
  container: "md:h-[80vh] h-[85vh] pb-10 gap-11 md:gap-0 md:p-0 relative flex flex-col w-full justify-end md:justify-center transition-all ease-in-out",
  sectionA: "md:pl-[48px] md:w-[692px] md:px-0 pl-6 pr-4",
  title: "m-title biome-bold text-2xl md:text-[32px] bg-blue w-11/12 gradient-text sm:leading-[3rem] sm:h-fit mb-4",
  story: "m-story max-w-[592px] text-white text-base md:text-[22px] biome-regular mb-8 sm:leading-9",
  preBtn: "text-yellow m-preBtn border-yellow border-[1px] sm:py-[1rem] sm:px-[2.4rem] mr-4 biome-regular",
  getBtn: "text-white m-getBtn biome-regular bg-lightBlue sm:py-[1rem] sm:px-[2.4rem]",
  sectionB: "md:absolute bottom-0 right-10 px-6 md:px-0",
  iconContainer: "w-full flex justify-between md:justify-center md:pl-12 mb-6 lg:mb-8 space-x-4 pb-0 ",
  icon: "w-[32px] h-[32px] sm:w-[40px] sm:h-[40px]",
  footericon: " md:w-8/12 h-full",
};

const DesktopHero = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [mIndex, setMIndex] = useState(3)
  const [mobileImage, setMobileImage] = useState('')
  const [image, setImage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openPresale, setOpenPresale] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (index === imagesArray.length - 1) {
        setIndex(0);
        getImgBlurUrl(0);
      } else {
        setIndex(index + 1);
        getImgBlurUrl(index);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [index]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (mIndex === 9) {
        setMIndex(3);
        getMobileImgBlurUrl(3);
      } else {
        setMIndex(mIndex + 1);
        getMobileImgBlurUrl(mIndex);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [mIndex]);
  useEffect(() => {
    blurImageUrl(`./assets/img/hero/image-and-lights-1.png`, 50).then(url => setImage(url));
    blurImageUrl(`./assets/img/hero/mobile/shapes and light-3.png`, 50).then(url => setMobileImage(url));
  }, [])

  // const handleAirdrop = () => {
  //   setOpenModal(!openModal);
  // };
  const handlePresale = () => {
    setOpenPresale(!openPresale);
  };
  const getImgBlurUrl = async (index) => {
    let result
    await blurImageUrl(`./assets/img/hero/image-and-lights-${index + 1}.png`, 50).then(url => result = url);
    setImage(result)
  }
  const getMobileImgBlurUrl = async (index) => {
    let result
    await blurImageUrl(`./assets/img/hero/mobile/shapes and light-${index}.png`, 50).then(url => result = url);
    setMobileImage(result)
  }
  return (
    <>
      <section className={styles.mainContainer} id='Home'>
        <div
          className={styles.container}
          style={{
            borderRadius: "30px",
            overflow: 'hidden',
            width: "100%",
          }}
        >
          <div className={styles.sectionA}>
            <Fade top delay={100}>
              <div className={styles.title}>
                <ColouredHeaderText text={t("h_future")} />
              </div>
            </Fade>
            <Fade bottom delay={500}>
              <p className={styles.story}>{t("h_story")}</p>
            </Fade>
            <Fade left delay={900}>
              <div className="grid grid-cols-2 gap-7 md:gap-[47px] max-w-max">
                <CustomButton text="Get Whitelisted" onClick={() => history.push("/get-whitelisted")} />
                <CustomButton text={t('Presale')} onClick={handlePresale} variant />
              </div>
            </Fade>
          </div>
          <div className={styles.sectionB}>
            <Fade right delay={1200}>
              <div className={styles.iconContainer}>
                {linksArray.map((item, index) => (
                  <a
                    href={item.link}
                    target="_blank"
                    key={index}
                    rel="noopener noreferrer"
                  >
                    <abbr title={`${item.name}`}>
                      <img src={item.icon} alt={item.name + 'icon'} className={styles.icon} />
                    </abbr>
                  </a>
                ))}
              </div>
            </Fade>
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 -z-10 hidden sm:block">
            <LazyImage
              placeholder={image}
              uri={`./assets/img/hero/image-and-lights-${index + 1}.png`}
              style={{ width: '100%', height: '80vh' }}
              key={index}
              render={(src, style) => <img src={src} style={{ minHeight: '80vh', width: '100%', minWidth: 'auto' }} alt="image1" />}
            />
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 -z-10 sm:hidden">
            <LazyImage
              placeholder={mobileImage}
              uri={`./assets/img/hero/mobile/shapes and light-${mIndex}.png`}
              style={{ width: '100%', height: '85vh' }}
              key={mIndex}
              render={(src, style) => <img src={src} style={{ minHeight: '85vh', width: '100%', minWidth: 'auto' }} alt="image2" />}
            />
          </div>
        </div>
      </section>
      <AirdropModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <PresaleModal
        open={openPresale}
        handleClose={() => setOpenPresale(false)}
      />
    </>
  );
};

export default DesktopHero;
