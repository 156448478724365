export const socials = [
  {
    key: "twitter",
    title: "Follow our Twitter page",
    link: "https://twitter.com/SportrexHQ",
  },
  {
    key: "discord",
    title: "Join Our Discord Server",
    link: "https://discord.gg/QMqmznUvq2",
  },
  {
    key: "instagram",
    title: "Follow our Instagram page",
    link: "https://www.instagram.com/sportrexofficial/",
  },
  {
    key: "telegram",
    title: "Join Our Telegram Channel",
    link: "https://t.me/sportrexofficial",
  },
];