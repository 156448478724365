import React from "react";
import ColouredHeaderText from "../common/ColouredHeaderText";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./roadmap.css";
// import roadm from "../assets/roadmap-temp.png"
import roader from "../assets/video/roadmap-tempo.gif"

const styles = {
  parentContainer: "md:hidden w-full h-full flex flex-col mb-10",
  title: "biome-bold md:text-xl max-w-max mx-auto mb-6 xxl:text-2xl gradient-text md:leading-[3rem] font-bols",
  mainContainer: "w-full h-full flow-hide-x",
  videoContainer: "w-full h-full relative",
  img: "w-auto h-auto",
};

const Roadmap = () => {
  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.title}>
            <ColouredHeaderText text={'Sportrex Roadmap'} />
          </div>
          <Swiper
            style={{
              "--swiper-pagination-color": "#3333FF"
            }}
            speed={600}
            parallax={true}
            pagination={{
              clickable: true
            }}
            modules={[Parallax, Pagination]}
            className="mySwiper"
          >
            <div
              slot="container-start"
              className="parallax-bg"
              style={{
                backgroundImage: `url(${roader})` 
              }}
              data-swiper-parallax="-50%"
            ></div>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
