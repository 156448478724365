import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const styles = {
  parentContainer: "w-full h-full lg:h-full flex-col px-4 py-8 md:p-8 biome-regular bg-white",
  btnContainer: "mx-auto max-w-max grid grid-cols-2 gap-8",
  activeBtn: "text-white text-[12px] md:text-[14px] bg-lightBlue biome-regular px-2 py-2 sm:px-6 sm:py-2",
  nonActive: "text-grey text-[12px] md:text-[14px]  border-2 biome-regular border-grey px-2 py-2 sm:px-6 sm:py-2",
  imgContainer: "w-full h-fit sm:h-full",
  img: "w-full lg:w-full h-auto mx-auto",
  textParent: "flex overflow-y-scroll flow-hide flex-col",
  textContainer: "flex flex-row gap-3 md:gap-6 lg:border-b-2 last-of-type:border-b-0 border-grey font-bold py-4",
  textName: "text-lightBlue",
  story: "m-story-feature biome-semibold text-[16px] xxl:text-base text-black leading-7 xxl:leading-7",
};
const sportrexGame = [
  {
    name: "Contender - ",
    story: `An all-in-one (P2E) mobile boardgame hub, comprising on-demand boardgames, tailored for all boardgames enthusiast. It features cutting-edge niche and generalist game modes, immersive UI, interactive social gaming, unique community experience, NFT in-game pieces and boards assets, 
            a whole new world of gaming fuelled economy and so much more.`,
    fileName: 'contender'
  },
  {
    name: " GGANBU - ",
    story: `Inspired by the Netflix hit "Squid Game"; My GGANBU "close friend/ partner" is a Mars themed (P2E) mobile game with MetaVerse experience. It presents a challenging & innovative way for building partnerships, teams and diverse & inclusive communities on the playground. 
            It also boasts multiple, interactive and fun games / game modes for teams of two.`,
    fileName: 'gganbu'
  },
  {
    name: "My GGANBU mini - ",
    story: `This is a less resource intensive version of My GGANBU. Same concept, lighter perspective. `,
    fileName: 'gganbu'
  },
  {
    name: "GEEKIST - ",
    story: `Geekist is a fun trivia game that cuts across countless topics and industries. The game rewards knowledge and provides a platform where "geeks" can learn more. Learning and earning has never been more fun!`,
    fileName: 'geekist'
  },

];

const SportrexGame = () => {
  // const { t } = useTranslation();
  return (
    <div className={styles.textParent}>
      {sportrexGame.map((item, index) => (
        <div className={styles.textContainer} key={index}>
          <div className='flex justify-center items-center shadow-lg bg-white min-w-[57px] h-[57px] md:min-w-[75px] md:h-[75px]'>
            <img src={`/assets/img/games/${item.fileName}.svg`} alt={item.name + 'logo'} />
          </div>
          <p className={styles.story}><span className={styles.textName}>{item.name}</span>{item.story}</p>
        </div>
      ))}
    </div>
  )
}

// #####################################################################################################

const FeatureGame = () => {
  const [allGames, setAllGames] = useState(true);
  const { t } = useTranslation();
  return (
    <div className={styles.parentContainer}>
      <div className={styles.btnContainer}>
        <button
          className={`${allGames ? styles.activeBtn : styles.nonActive}`}
          onClick={() => setAllGames(true)}
        >
          {t("AG")}
        </button>
        <button
          className={`${allGames ? styles.nonActive : styles.activeBtn}`}
          onClick={() => setAllGames(false)}
        >
          {t("SG")}
        </button>
      </div>
      <div className={styles.imgContainer}>
        {allGames ? (
          <img
            src="https://media.publit.io/file/allGames.png"
            className={styles.img}
            alt="sportrex games"
          />
        ) : (
          <SportrexGame />
        )}
      </div>
    </div>
  );
}

export default FeatureGame