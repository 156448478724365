import React, { useState } from 'react'
import Tokenmetrics from './Tokenmetrics'
import SPT from './SPT'
import MultiChain from './MultiChain'
import { useTranslation } from "react-i18next";
import ColouredHeaderText from '../../common/ColouredHeaderText';

const styles = {
  parentContainer: "hidden md:block w-full h-full relative px-4 sm:px-16 space-y-4 flex flex-col biome-regular max-w-[1440px] mx-auto",
  title: "text-center flex justify-center mb-10",
  container: "w-full desktopBg h-full shadow-md  text-white",
  tabsContainer: "w-full border-b-2 border-grey flex",
  activeTab: "bg-lightBlue",
  inactiveTab: "text-white",
  tab: "w-1/3 text-white text-center py-8 text-lg cursor-pointer",
  tabContent: "w-full h-full",
};

const Tokennomics = () => {
  const [selected, setSelected] = useState('MultiChain')
  const { t } = useTranslation();

  return (
    <div className={styles.parentContainer}>
      <div className={styles.title}>
        <ColouredHeaderText text={t("tokenomics")} />
      </div>
      <div className="blur-circle bg-index" />
      <div
        className={styles.container}
        style={{
          backgroundColor: "#020633",
          width: "100%",
          height: "fit-content",
          zIndex: "100",
          borderRadius: "30px",
        }}
      >
        <div className={styles.tabsContainer}>
          <div
            className={`${styles.tab} ${selected === "MultiChain" ? styles.activeTab : styles.inactiveTab
              } mtc`}
            onClick={() => setSelected("MultiChain")}
          >
            {t("multi_chain")}
          </div>
          <div
            className={`${styles.tab} ${selected === "SPT" ? styles.activeTab : styles.inactiveTab
              }`}
            onClick={() => setSelected("SPT")}
          >
            {t("SPT")}
          </div>
          <div
            className={`${styles.tab} ${selected === "Tokenmetrics"
              ? styles.activeTab
              : styles.inactiveTab
              } tokenM`}
            onClick={() => setSelected("Tokenmetrics")}
          >
            {t("tokenmetrics")}
          </div>
        </div>
        <div className={styles.tabContent}>
          {selected === "SPT" && <SPT />}
          {selected === "Tokenmetrics" && <Tokenmetrics />}
          {selected === "MultiChain" && <MultiChain />}

          {/* {selected} */}
        </div>
      </div>
      <div className="blur-circle-purple bg-index" />
      <div className="blur-circle-purple-r bg-index" />
    </div>
  );
}

export default Tokennomics