export const styles = {
  parentContainer: "bg-blue w-full h-auto max-h-full p-6 flex flex-col gap-6 flow-hide rounded-2xl outline-none border-none",
  headerSession: "w-full h-full",
  header: "flex justify-center relative",
  header1: "flex w-7/12 justify-between items-center",
  headerTitle: "lg:text-lg text-md biome-bold font-bold text-white",
  closeIcon: "text-grey absolute right-0 text-xl cursor-pointer",
  formContainer: "flex flex-col w-full h-full",
  singleField: "flex flex-col w-full mb-6",
  label: "m-story biome-bold mb-4 text-white md:text-md ",
  input: "border-none outline-none text-white bg-semiBlue w-full",
  inputContainer: "py-2 px-4 bg-semiBlue rounded-lg mb-4",
  textArea: "border-none outline-none text-white bg-semiBlue w-full h-24",
  btnContainer: "w-full flex justify-center items-center",
  btn: "text-white m-ctaBtn biome-semibold bg-lightBlue  py-[1rem] px-[2.4rem] mt-[1rem] md:mt-[0rem]",
  wait: "flex space-x-4 items-center",
  waiting: "biome-regular text-white sm:text-md",
  validate: "text-yellow biome-regular text-sm md:text-md text-center",
  socialTitle: 'biome-bold text-[18px] mb-6 text-white'
};