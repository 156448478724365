import  { useState, useEffect } from "react";
import blurImageUrl from 'blur-image-url';

const imagesUrl = [
  "https://media.publit.io/file/q_50/banner-3-1.png",
  "https://media.publit.io/file/ads4.png",
  "https://media.publit.io/file/ads3.png",
  "https://media.publit.io/file/ads2.png",
  "https://media.publit.io/file/ads1.png",
  "https://media.publit.io/file/ads5.png",
  "https://media.publit.io/file/ads6.png",
];

const styles = {
  container: "max-w-[1440px] mx-auto mb-20 md:mb-32 md:block w-full h-[184px] px-4 sm:px-16 lg:h-[432px] flex justify-center items-center transition-all ease-in-out",
  image: "w-full h-full",
};
const AddsCard = () => {
  const [index, setIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      if (index === imagesUrl.length - 1) {
        setIndex(0);
        getImgBlurUrl(0);
      } else {
        setIndex(index + 1);
        getImgBlurUrl(index);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [index]);

  const getImgBlurUrl = async (index) => {
    let result
    await blurImageUrl(`./assets/img/section/${index + 1}.png`, 50).then(url => result = url);
   
  }

  return (
    <section className={styles.container}>
      <img src={`./assets/img/section/${index + 1}.png`} style={{ width: '100%' }} className={styles.image} alt="Section index 1" />
    </section>
  );
};

export default AddsCard;
