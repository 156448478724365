import React, { useState } from "react";
// import Tokenmetrics from "./Tokenmetrics";
import Tokenmetrics from "../Tokenomics/Tokenmetrics";
import SPT from "./SPT";
import MultiChain from "./MultiChain";
// import token from "../../assets/Token-BG.png";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ColouredHeaderText from "../../common/ColouredHeaderText";

const styles = {
  parentContainer: "w-full md:hidden px-4 h-full relative flex flex-col mb-20",
  title: "mx-auto max-w-max mb-6",
  container: "w-full bg-blue h-full flex flex-col shadow-md text-white relative",
  tabsContainer: "w-full border-b-2 border-grey flex",
  headerContainer: "bg-lightBlue w-full border-token w-full h-full",
  mobileHeader: "biome-semibold text-center py-5",
  activeTab: "bg-lightBlue biome-semibold text-white py-6 text-2xl cursor-pointer",
  inactiveTab: "text-white py-6 text-2xl cursor-pointer",
  iconContainer: "mt-8 mx-auto flex items-center gap-3",
  tab: "w-1/3 text-center p-2",
  greyIcon: "text-grey text-[1.4rem] cursor-pointer outline-none border-none ",
  blueIcon:
    "text-lightBlue text-[1.4rem] cursor-pointer outline-none border-none ",
  tabContent: "w-full h-[34rem] flow-hide ",
};

const Tokennomics = () => {
  const { t } = useTranslation();

  // const [selected, setSelected] = useState(index);
  const arrayContainer = ["multi_chain", "SPT", "tokenmetrics"];

  const [count, setCount] = useState(0);

  return (
    <div className={styles.parentContainer}>
      <div className={styles.title}>
        <ColouredHeaderText text={t("tokenomics")} />
      </div>
      <div className="blur-circle bg-index" />
      <div
        className={styles.container}
        style={{
          background: "#020733",
          width: "100%",
          height: "fit-content",
          zIndex: "100",
          borderRadius: "1rem",
        }}
      >
        <div className={styles.headerContainer}>
          <h1 className={styles.mobileHeader}>
            {/* {arrayContainer[count]} */}
            {t(`${arrayContainer[count]}`)}
          </h1>
        </div>

        <div className={styles.tabContent}>
          {arrayContainer[count] === "SPT" && <SPT />}
          {arrayContainer[count] === "tokenmetrics" && <Tokenmetrics />}
          {arrayContainer[count] === "multi_chain" && <MultiChain />}
        </div>
      </div>
      <div className={styles.iconContainer}>
        <button
          onClick={() => setCount(count - 1)}
          disabled={count === 0}
          className={`${count === 0 ? styles.greyIcon : styles.blueIcon}`}
        >
          <BsFillArrowLeftCircleFill />
        </button>
        <p className="text-[#fff]"> {count + 1}/3</p>
        <button
          onClick={() => setCount(count + 1)}
          disabled={count === 2}
          className={`${count === 2 ? styles.greyIcon : styles.blueIcon}`}
        >
          <BsFillArrowRightCircleFill />
        </button>
      </div>
      <div className="blur-circle-purple bg-index" />
      <div className="blur-circle-purple-r bg-index" />
    </div>
  );
};

export default Tokennomics;
