import React from 'react'
import ilus from "../../assets/svg/spt-token.svg"
import sptd from "../../assets/svg/spt-det.png"



const Tokenmetrics = () => {
  return (
    <>
      <div className='w-full flex space-y-8 lg:space-y-0 flex-col lg:flex-row lg:space-x-8 py-8 px-4'>
        <div className="w-full lg:w-1/2 relative flex justify-center">
          <div className="grad-token w-[200px] h-[200px] xl:w-[550px] xl:h-[550px] absolute left-0 right-0 top-0 mx-auto bottom-0" />

          
          <img src={ilus} alt="ilus" className='w-full h-auto'  />
        </div>
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <img src={sptd} alt="spt-d" className="w-full h-auto" />
        </div>
     </div>
    </>
  );
}

export default Tokenmetrics