import React from "react";
import CustomButton from "../Button/CustomButton";

const styles = {
  contentContainer: "w-full flex flex-col items-center",
  imgContainer: "flex justify-center my-10",
  text: "biome-bold text-white text-md text-center mb-10",
};

const ErrorComponent = ({ message, handleBack }) => {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.imgContainer}>
        <img
          src="https://media.publit.io/file/erro-logo.png"
          alt="success"
          width={160}
          height={160}
        />
      </div>
      <p className={styles.text}>{message}</p>
      <CustomButton text={'Back'} onClick={handleBack} />
    </div>
  );
};

export default ErrorComponent;
