import React, { useState } from "react";
import Success from "../Feedback/Success";
import Error from "../Feedback/Error";
import { FaTimes } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styles } from "./styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomButton from "../Button/CustomButton";

const url = "https://sportrex-be.herokuapp.com/api/airdrops";

const Api = ({ handleClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(url, { email, name })
      if (response.status === (201 || 200)) {
        setSuccess(true)
        setMessage(response.data.message)
        setEmail('')
        setName('')
      } else {
        setError(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setError(true);
      setMessage(error.response.data.error);
    }
    setLoading(false)
  };

  const handleBack = () => {
    setError(false);
    setSuccess(false)
  }

  return (
    <div className={styles.parentContainer}>
      <div className={styles.headerSession}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>{t("join_list")}</h1>
          <FaTimes className={styles.closeIcon} onClick={handleClose} />
        </div>
      </div>
      {success && <Success message={message} handleBack={handleBack} />}
      {error && <Error message={message} handleBack={handleBack} />}
      {!error && !success &&
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("full_name")}</label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.input}
                placeholder="Wiz Gamer"
                value={name}
                name='name'
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className={styles.singleField}>
            <label className={styles.label}>{t("email")}</label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                placeholder="gamersmerge@sportrex.eth"
                type="email"
                value={email}
                name='email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className={styles.btnContainer}>
            {loading ? (
              <div className={styles.wait}>
                <p className={styles.waiting}>{t("p_wait")}</p>
                <CircularProgress color="inherit" className="text-white" />
              </div>
            ) : (
              <div role={'button'} type="submit">
                <CustomButton text={t("Get_Airdrop")} onClick={() => { }} />
              </div>
            )}
          </div>
        </form>
      }
    </div>
  );
};

export default Api
