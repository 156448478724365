import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import sendicon from "../assets/sendicon.png";
import axios from "axios";
import SuccessSnack from "./Snackbar/SuccessSnack"
import ErrorSnack from './Snackbar/ErrorSnack'
import CircularProgress from "@material-ui/core/CircularProgress";

const url =
  "https://sportrex-webiste-api-fb47fa67972d.herokuapp.com/api/v1/sportrex/subscribers-blowx";

const styles = {
  container: "hidden md:flex w-full h-full justify-center px-4 sm:px-16 max-w-[1440px] mx-auto",
  contentContainer: "flex flex-col items-center py-16",
  inputContainer: "w-full max-w-[597px] bg-white flex justify-between rounded-[2.5rem] px-6 py-4",
  input: "border-none outline-none w-10/12 text-grey-dark text-lg biome-regular",
  title: "text-[1.375rem] sm:text-[1.5rem] mb-16 text-white md:text-[30px] 2xl:text-[35px]",
  img: "w-10 h-10 cursor-pointer",
  validation: "text-yellow text-xl biome-regular",
};

const Subscribe = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      // setTimeout(() => {
      //   setSuccess(true);
      //   setMessage("Congratulations you have successfully subscribed to our news letter");
      //   setEmail("");
      //   setLoading(false);
      // }, 3000);
    try {
      const response = await axios.post(url, { email })
      if (response.data?.success) {
        setSuccess(true);
        setMessage(response.data?.message);
        setEmail("");
      } else {
        setError(true);
        setMessage(response.data?.error);
      }
    }
    catch (error) {
      setError(true);
      setMessage(error.response.data?.error);
    }
    setLoading(false)
  }
  return (
    <>
      <section className={styles.container}>
        <div
          style={{
            background: "url(https://media.publit.io/file/susbscribe-bg.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "334px",
            width: "100%",
            borderRadius: "50px",
          }}
        >
          <div className={styles.contentContainer}>
            <h1 className={styles.title} >
              {t("be_part")}
            </h1>
            <form className={styles.inputContainer} onSubmit={handleSubmit}>
              <input
                className={styles.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="johndoe@gmail.com"
                name='email'
                required
              />
              {loading ? (
                <CircularProgress color="inherit" className="text-blue" />
              ) : (
                <button type='submit'>
                  <img
                    className={styles.img}
                    src={sendicon}
                    alt="subscribe"
                    role={'button'}
                  />
                </button>
              )}
            </form>
          </div>
        </div>
      </section>
      <SuccessSnack message={message} open={success} setOpen={setSuccess} />
      <ErrorSnack open={error} setOpen={setError} message={message} />
    </>
  )
}

export default Subscribe;
